import React from 'react';
import {HashLoader} from 'react-spinners'

const AnimationSpinner = ({ show }) => {
	return (
		<div id='animation-bar-spinner' style={{ display: show ? 'block' : 'none' }}>
			<div className="animation-spinner">
				<HashLoader
					color="#d52aba"
					loading={show}
					size={100}
					speedMultiplier={2}
				/>
			</div>
		</div>
	);
};

export default AnimationSpinner;

// css imports
import { useContext } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// libraries imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContext } from "./context/AppContext";

// component imports
import Loginpage from "./pages/loginPage";
import ForgotPassword from "./pages/forgotPassword";
import Dashboard from "./pages/dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";
import Dfy from "./pages/dfy";
import Reseller from "./pages/reseller";
import WhiteLabel from "./pages/whiteLabel";
import Profile from "./pages/profile";
import ResetPassword from "./pages/resetPassword";
import TrainingVideos from "./pages/trainingVideos";
import UpGradePage from "./pages/upGrade";
import ForgotPassReset from "./pages/forgotPassReset";
import AgencyCreate from "./pages/agency/create";
import AgencyList from "./pages/agency/list";
import OpenAi from "./pages/openAI"
import SiteClone from './pages/site/clone-site/SiteClone'
import ManageSite from "./pages/site/manage-site/ManageSite";
import Domain from "./pages/domain/Domain";
import Chat from '../src/pages/aichatbot/Chat'
// import ManageDomain from "./pages/domain/ManageDomain";
import DomainList from "./pages/domain/DomainList";
import WebSiteBuilder from '../src/pages/websiteBuilder/WebSiteBuilder'
import { VideoClonePage, SearchPage, YoutubePage, VimeoPage, UploadPage, PreviewVideo, ListVideos } from './pages/video-clone'

import AiCopyWriter from "./pages/ai_copy_writer/AiCopyWriter";
import CopyWriterForm from "./pages/ai_copy_writer/CopyWriterFrom";
// import AIStocks        from "./pages/aiStocks/AIStocks";
import {AIStocks, AiStockVideos,AistockGifs, AiStockImages, AiStockAudios} from './pages/aiStocks'

const AppContent = () => {
	const { base_url, isSidebarOpen, menuPermissionArray, menuFullPermission } = useContext(AppContext);
	return (
		<>
			<Routes>
				<Route
					path="/login"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<Loginpage base_url={base_url} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/password-reset/:varificationCode"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<ForgotPassReset base_url={base_url} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/forgot-password-request"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<ForgotPassword base_url={base_url} />
							<Footer />
						</ProtectedRoute>
					}
				/>
				<Route
					path="*"
					element={
						<ProtectedRoute isLoginRoute={true}>
							<Loginpage base_url={base_url} />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/dashboard"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Dashboard base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/dfy"
					element={
						<ProtectedRoute packageRequiredFeature="dfy" menuRequiredPermission="dfy" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Dfy base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/reseller"
					element={
						<ProtectedRoute packageRequiredFeature="reseller" menuRequiredPermission="reseller" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Reseller base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/profile"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Profile base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/pass-reset"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ResetPassword base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/whitelabel"
					element={
						<ProtectedRoute packageRequiredFeature="whitelabel" menuRequiredPermission="whitelabel" >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<WhiteLabel base_url={base_url} />
								</div>
							</div>
							<Footer />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/training-videos"
					element={
						<ProtectedRoute  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<TrainingVideos base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/upgrade"
					element={
						<ProtectedRoute menuRequiredPermission="upgrade"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<UpGradePage base_url={base_url} />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/create-agency"
					element={
						<ProtectedRoute packageRequiredFeature="agency" menuRequiredPermission="create_agency"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper transition ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AgencyCreate base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/manage-agency"
					element={
						<ProtectedRoute packageRequiredFeature="agency" menuRequiredPermission="manage_agency"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AgencyList base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/open-ai"
					element={
						<ProtectedRoute menuRequiredPermission="open_ai"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<OpenAi base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/clone-site"
					element={
						// <ProtectedRoute menuRequiredPermission="open_ai"  >
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<SiteClone base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-site"
					element={
						<ProtectedRoute menuRequiredPermission="open_ai"  >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ManageSite base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/create-domain"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Domain base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/manage-domain"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<DomainList base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-video-clone"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<VideoClonePage base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-video-clone/search"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<SearchPage base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-video-clone/youtube"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<YoutubePage base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-video-clone/vimeo"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<VimeoPage base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-video-clone/upload"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<UploadPage base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-video-clone/preview-video/"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<PreviewVideo base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/edited-videos"
					element={
						<ProtectedRoute >
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<ListVideos base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-copy-writer"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AiCopyWriter base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-copy-writer/:writerType"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<CopyWriterForm base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				{/* <Route
					path="/ai-content-writer"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AIContentWriter base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/> */}
				<Route
					path="/ai-chatbot"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<Chat base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				
				<Route
					path="/website-builder"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<WebSiteBuilder base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-stock"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AIStocks base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-stock/audios"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AiStockAudios base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-stock/videos"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AiStockVideos base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-stock/gifs"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AistockGifs base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/ai-stock/images"
					element={
						<ProtectedRoute>
							<Header />
							<div className="main d-flex">
								<div className="sidebar-wrapper">
									<Sidebar />
								</div>
								<div className={`content-wrapper ${isSidebarOpen ? 'pageOpen' : 'pageClose'}`}>
									<AiStockImages base_url={base_url} />
									<Footer />
								</div>
							</div>
						</ProtectedRoute>
					}
				/>


			</Routes>
		</>
	);
};

function App() {
	return (
		<BrowserRouter>
			<AppContent />
		</BrowserRouter>
	);
}

export default App;

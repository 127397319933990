import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AnimationSpinner from "../../../AnimationSpinner";
import { useNavigate } from "react-router-dom";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import Select from 'react-select';

const SiteClone = ({ base_url }) => {
    const [loading, setLoading] = useState(false);
    const [fetchedDomainDetails, setFetchedDomains] = useState([]);
    const [subdomain, setSubdomain] = useState([]);
    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");
    const [permission, setPermission] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [options, setOptions] = useState([]);
    const handleDomainChange = (selceted) => {
        setSubdomain(selceted)
    }

    const navigate = useNavigate();


    const handleSubmit = (event) => {
        event.preventDefault();
        if (isSubmitted) return;
        setIsSubmitted(true);
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }

            let isValid = true;
            if (!url) {
                setIsSubmitted(false);
                Swal.fire({
                    icon: "error",
                    title: "Validation Error",
                    text: "Url is required!",
                });
                isValid = false;
            } /*else if (!permission) {
                Swal.fire({
                    icon: "error",
                    title: "Validation Error",
                    text: "Permission is required!",
                });
                isValid = false;
            }*/
            else if (!title) {
                Swal.fire({
                    icon: "error",
                    title: "Validation Error",
                    text: "Title is required!",
                });
                isValid = false;
            }
            else if (!subdomain.value) {
                Swal.fire({
                    icon: "error",
                    title: "Validation Error",
                    text: "Domain is required!",
                });
                isValid = false;
            }
            if (!isValid) {
                return;
            }

            setLoading(true);
            const formData = new FormData();
            formData.append("token", token);
            formData.append("site-url", url);
            formData.append("page-title", title);
            formData.append("sub-domain-id", subdomain.value);
            formData.append("action", 'get_one_time_login_code');
            axios
                .post(`${base_url}api/user/clone-site`, formData)
                .then((response) => {
                    if (response.data.status === true) {
                        setLoading(false);
                        Swal.fire({
                            icon: "success",
                            title: "Site Cloned",
                            text: "Your Sites Clone has been successfully added.",
                        });
                        // window.open(response.data.redirect_url, '_blank');
						//window.location.href = response.data.redirect_url;
                        navigate('/manage-site');
                        setIsSubmitted(false);
                    } else {
                        setLoading(false);
                        setIsSubmitted(false);
                        if (response.data.reset === true) {
                            handleInvalidToken(navigate);
                            return;
                        }
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: response.data.message,
                        });
                    }
                });
        } catch (error) {
            setLoading(false);
            setIsSubmitted(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        setUrl("")
        setPermission(false)
    }


    const fetchDomains = async () => {
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            setLoading(true)
            const formData = new FormData();
            formData.append("action", "get_domains");
            formData.append("token", token);
            const response = await axios.post(
                `${base_url}api/user/get-domain-details`,
                formData
            );
            if (response.data.status === true) {
                setLoading(false);
                setFetchedDomains(response.data.domains);
                const domainOptions = response.data.domains.map(domain => ({
                    label: domain.sub_domain_name,
                    value: domain.id
                }));

                setOptions((options) => (
                    [{
                        label: "Select Domain",
                        value: null
                    },
                    ...domainOptions
                    ]
                )); // Update options state with domain names
            } else {
                setLoading(false)
                if (response.data.reset === true) {
                    handleInvalidToken(navigate);
                    return;
                }
            }
        } catch (error) {
            setLoading(false)
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };
    useEffect(() => {
        fetchDomains();
    }, []);

    return (
        <>
            <div className="container h-100">
                <AnimationSpinner show={loading} />
                <div className="right-container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mx-auto">
                            <div className="form-card">
                                <div className="form-body">
                                    <div className="head-part">
                                        <h6 className="title">Import Page</h6>
                                    </div>
                                    <form
                                        acceptCharset="utf-8"
                                        autoComplete="off"
                                    >
                                        <div className="card-body">
                                            <div className="row form-group mb-0">
                                                <div className="col-md-10 mb-3 mx-auto">
                                                    <label className="form-label">
                                                        Domain{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={options[0]}
                                                        value={subdomain}
                                                        isSearchable={true}
                                                        name="countries"
                                                        options={options}
                                                        onChange={handleDomainChange}
                                                        placeholder={"select domain"}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                minHeight: "36px",
                                                                fontSize: "13px",
                                                                borderRadius: "8px",
                                                                border: state.isFocused ? '1px solid rgb(88, 88, 88)' : '1px solid rgb(88, 88, 88)',
                                                                boxShadow: state.isFocused ? null : null,
                                                            }),
                                                            indicatorContainer: (provided) => ({
                                                                ...provided,
                                                                padding: "2px 8px",
                                                            }),
                                                            hlgwow: (provided) => ({
                                                                padding: "2px 8px",
                                                                padding: "0px 8px",
                                                            }),
                                                            menu: (base) => ({
                                                                ...base,
                                                                fontSize: '14px', // Change the font size of the menu
                                                            }),
                                                            placeholder: (base) => ({
                                                                ...base,
                                                                fontSize: "16px",
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-10 mb-3 mx-auto">
                                                    <label className="form-label">
                                                        Site Title{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter your site title...."
                                                        className="form-control custom-form-control"
                                                        value={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-10 mb-3 mx-auto">
                                                    <label className="form-label">
                                                        External url{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="https://www.example.com"
                                                        className="form-control custom-form-control"
                                                        value={url}
                                                        onChange={(e) => setUrl(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                {/* <div className="mb-3 col-md-10 mx-auto">
                                                    <input
                                                        type="checkbox"
                                                        checked={permission}
                                                        onChange={() => setPermission((prev) => !prev)}
                                                        required
                                                    />
                                                    <label className="form-label">
                                                        I confirm that this page belongs to me or I have permission to use it.{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-10 mx-auto">
                                            <button
                                                type="submit"
                                                className="btn btn-primary px-4 mt-2 mb-2 me-4"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                className="btn btn-primary px-4 mt-2 mb-2"
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SiteClone

import React, { useState, useContext } from 'react';
import AnimationSpinner from '../../AnimationSpinner';
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

const CreateCampaign = () => {
    const { setSelectedMethod, selectedMethod } = useContext(AppContext);
    const navigate = useNavigate();

    const methods = [
        { label: "Search for a video", value:"search" },
        { label: "YouTube Videos", value:"youtube"},
        { label: "Vimeo Videos", value:"vimeo" },
        { label: "Direct Upload", value:"upload"},
    ];


    const handleMethodChange = (method) => {
        setSelectedMethod(method.value);
      };

    const handleSubmit = (e)=>{
        e.preventDefault()
        navigate(`/ai-video-clone/${selectedMethod}`)
    }
      
        return (
            <div className="right-container">
                <AnimationSpinner show={false} />
                <div className="container h-75">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-6">
                            <h2>Create A New Campaign</h2>
                            <p>Kindly, select your preferred method</p>

                            <form onSubmit={handleSubmit} className="method-container p-4 border rounded">
                                {methods.map((method, index) => (
                                    <div
                                        key={method.value}
                                        className={`video-clone-input-container w-100 py-2 px-4 border rounded mb-4 mt-4 d-flex justify-content-between ${selectedMethod === method.value ? "selected" : ""
                                            }`}
                                        onClick={() => handleMethodChange(method)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <label htmlFor={`method-${method.id}`} className="fs-6 cursor-pointer">
                                            {index +1}. {method.label}
                                        </label>
                                        <input
                                            type="radio"
                                            name="method"
                                            id={`method-${method.id}`}
                                            checked={ method.value === 'search' || selectedMethod === method.value}
                                            onChange={() => handleMethodChange(method)}
                                        />
                                    </div>
                                ))}
                                <div className="btn-container w-100 d-flex justify-content-end align-items-center">
                                    <button type='submit' className='btn btn-dark px-3 py-1'>Continue{"  "}<IoIosArrowForward /></button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        );
    };

    export default CreateCampaign;

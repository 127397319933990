import react, { useState } from 'react';
import { FaElementor } from "react-icons/fa";

const BaseElement = ({ elements, onElementAddClick }) => {

    return (
        <div className="row element-tab1">
            <div className="col-lg-12 my-2 cursor-pointer">
                <div
                    className="border d-flex justify-content-center align-items-center p-2 element1"
                    style={{ backgroundColor: "#e2d7f559" }}
                >
                    <div className="m-0 fw-semibold d-flex align-items-center"><span className='me-1'><FaElementor /></span><p className='p-0 m-0'>Add Elements</p></div>
                </div>
            </div>
            {elements.map((element, index) => (
                <div
                    className={`col-lg-6 mb-4 cursor-pointer ${index === 0 || index === 1 ? "mt-3" : ""
                        }`}
                    key={index}
                    onClick={() => onElementAddClick(element)}
                >
                    <div
                        className="shadow-sm rounded d-flex justify-content-center align-items-center flex-column px-2 py-2 gap-2"
                        style={{ backgroundColor: "#e2d7f559" }}
                    >
                        {element.icon}
                        <p className="m-0 text-center">{element.name}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BaseElement;
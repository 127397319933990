import React, { useState } from 'react'
import AnimationSpinner from '../../AnimationSpinner'
import { Link, useNavigate } from 'react-router-dom'

// react icons import
import { FaBloggerB } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";

const AiCopyWriter = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const cards = [
        { name: "Blog Post Writer", icon: <FaBloggerB />, type: "blog" },
        { name: "Email Writer", icon: <MdEmail />, type: "email" },
        { name: "Paragraph Writer", icon: <IoDocumentTextOutline />, type: "paragraph" }

    ]

    const handleCardClick = (type) => {
        navigate(`/ai-copy-writer/${type}`)
    }
    return (
        <div className="right-container">
            <AnimationSpinner show={false} />
            <div className="container h-75">
                <div className='p-2 border-bottom dashed mb-3'>
                    <h3>AI Copy Writer</h3>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <Link className="btn btn-primary mb-4">Manage Copies</Link> */}
                            {/* <!-- Content type options shown as boxes --> */}
                            <div id="contentOptions" className="card-body border rounded p-4">
                                <div className="d-flex justify-content-between align-items-center gap-4">
                                    {cards.map((card, index) => (
                                        <div onClick={() => handleCardClick(card.type)} key={card.type} className="option-box-container border rounded p-4">
                                            <div className="option-box" data-type="blog">
                                                <div className="div-icon-box">
                                                    {card.icon}
                                                </div>
                                                <h5>{card.name}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AiCopyWriter
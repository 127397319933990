import React, { useState, useContext, useEffect } from "react";
import GetToken from '../../GetToken'
import { handleInvalidToken } from '../../AuthUtils'
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import Swal from "sweetalert2";
import emptyIcon from '../../assests/images/emptyIcon.png';

// react icons import 
import { IoIosArrowBack, IoIosSearch, IoIosArrowForward } from "react-icons/io";


const SearchPage = ({ base_url }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [isSelected, setIsSelected] = useState(false);
	const [selectedVideoId, setSelectedVideoId] = useState();
	const [selectedVideoTitle, setSelectedVideoTitle] = useState("")
	const [selectedVideoDescription, setSelectedVideoDescription] = useState("")
	const [isSubmitted, setIsSubmitted] = useState(false);               // flag stops user to immediately submit again
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const { setVideoData } = useContext(AppContext)
	const [videoDuration, setVideoDuration] = useState(0);

	// Function to fetch data from YouTube API based on the search query
	const fetchData = async (query) => {
		setLoading(true);
		setError(null);
		try {
			const response = await fetch(
				`https://www.googleapis.com/youtube/v3/search?relevanceLanguage=en&videoEmbeddable=true&videoCaption=closedCaption&part=snippet&type=video&q=${query}&maxResults=40&key=AIzaSyA5mDakeTPC_Vhg0Ku5DGxihl9hbRFutfg`
			);
			const result = await response.json();
			if (response.ok) {
				setData(result.items || []);
			} else {
				setError("Failed to fetch data from YouTube API");
			}
		} catch (err) {
			setError("Error fetching data");
		} finally {
			setLoading(false);
		}
	};

	// Trigger the search when the search icon is clicked
	const handleSearchClick = (e) => {
		e.preventDefault();
		if (searchQuery.trim()) {
			fetchData(searchQuery);
			setIsSelected("")
			setSelectedVideoId("")
			setSelectedVideoDescription("")
		}
	};

	// Handle video click navigation
	const handleVideoClick = (item) => {
		setIsSelected(true);
		setSelectedVideoId(item.id.videoId);
		setSelectedVideoTitle(item.snippet.title);
		setSelectedVideoDescription(item.snippet.description);
	};

	const handleGoingBack = (e) => {
		e.preventDefault();
		navigate("/ai-video-clone");
	};

	const handleSubmit = async () => {
		if (isSubmitted) {
		}
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("title", selectedVideoTitle);
			formData.append("url", `https://www.youtube.com/embed/${selectedVideoId}`);
			formData.append("type", 'search');
			formData.append("description", selectedVideoDescription);
			formData.append("token", token); 
			formData.append("action", 'videoClone');
			const response = await axios.post(
				`${base_url}api/user/video_clone`,
				formData
			);
			if (response.data.status === true) {
				setLoading(false);
				setVideoData((data) => (
					{ ...data, 
						videoId:selectedVideoId,
						url: response.data.video_url, 
						title: response.data.title, 
						source: response.data.source, 
						type: 'youtube', 
						slug: response.data.slug, 
						description: selectedVideoDescription}
				))
				navigate("/ai-video-clone/preview-video/");
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	}


	useEffect(() => {
		if (data.length > 0) {

		}
	}, [data])

	return (
		<div className="right-container video-search-page">
			<AnimationSpinner show={loading} />
			<div className="container h-75">
				<div className="row h-100 justify-content-center align-items-center">
					<div className="col-10 mt-4">
						<h2 className="mb-4">Search for a youtube video</h2>
						<div className="px-4 pt-2 pb-4 border rounded">
							<form onSubmit={handleSearchClick} className="w-100 px-4 mb-4 mt-4 d-flex flex-column gap-2 position-relative">
								<label
									htmlFor="video-search"
									className="fs-4 cursor-pointer mb-3"
								>
									Search video
								</label>
								<input
									className="form-control video-search-input-container"
									type="text"
									name="video-search"
									id="video-search"
									placeholder="search using keyword..."
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
								<button className="border-0">
									<IoIosSearch
										className="search-icon position-absolute"
									/>
								</button>
							</form>

							<div className="result-container w-100 py-2 px-4 mb-4 mt-4 d-flex flex-column gap-2 min-h-0 max-height-400 overflow-y-scroll cursor-pointer">
								{loading && !error && <p>Loading...</p>}
								{error && <p className="text-danger">{error}</p>}
								{data && data.length > 0 ? (
									data.map((item) => (
										<div
											key={item.id.videoId}
											onClick={() => handleVideoClick(item)}
											className={`result-item d-flex gap-4 border rounded p-2 ${selectedVideoId == item.id.videoId ? "selected" : ""
												}`}
										>
											<div className="result-image-container">
												<img
													src={item.snippet.thumbnails.high.url}
													alt="result-img"
												/>
											</div>
											<div className="info-container d-flex flex-column gap-2">
												<h5>{item.snippet.title}</h5>
												<p>{item.snippet.description}</p>
											</div>
										</div>
									))
								) : (
									<div className="row mt-4 mb-5">
										<div className="col-lg-5 col-md-8 mx-auto">
											<div className="empty-box">
												<div className="card-body">
													<div className="list-empty-box">
														<div className="icon">
															<img src={emptyIcon} alt="No data available" />
														</div>
														<p>No data available</p>
													</div>
												</div>
											</div>
										</div>
									</div>)}
							</div>

							<div className="btn-container w-100 d-flex justify-content-end gap-2 align-items-center">
								<button
									onClick={handleGoingBack}
									className="btn btn-dark px-3 py-1"
								>
									<IoIosArrowBack />
									{"  "}Back
								</button>
								{isSelected && (
									<button
										onClick={() => handleSubmit()}
										className="btn btn-dark px-3 py-1"
									>
										Continue{"  "}
										<IoIosArrowForward />
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchPage;

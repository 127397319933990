import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AnimationSpinner from "../../AnimationSpinner";
import Button from "react-bootstrap/Button";
import { FaDownload } from "react-icons/fa6";
import { truncateTextByCharacters } from '../../util'
import { Link } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
const AiStockAudios = () => {
  const [audioData, setAudioData] = useState([]);
  const [audioQuery, setAudioQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const handleAudioSearch = async (e) => {
    e.preventDefault();

    if (!audioQuery.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Enter Keyword To Generate Audio",
      });
      return;
    }

    setLoading(true);
    setAudioData([]);
    setCurrentPage(1);

    await fetchAudio(audioQuery, 1);
  };

  const fetchAudio = async (query, page) => {
    try {
      const searchUrl = `https://freesound.org/apiv2/search/text/?query=${query}&page=${page}&page_size=12`;
      const searchResponse = await axios.get(searchUrl, {
        headers: {
          Authorization: "token 2S0I5sgTxjMfbQK8KrvsncbHrmQOmTCkxvjTFoUd",
        },
      });

      if (!searchResponse.data.results.length && page === 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Audio Not Found! Please Try Again",
        });
        setHasMore(false);
        return;
      }

      const audioDetailsPromises = searchResponse.data.results.map(async (result) => {
        const detailUrl = `https://freesound.org/apiv2/sounds/${result.id}?token=2S0I5sgTxjMfbQK8KrvsncbHrmQOmTCkxvjTFoUd`;
        const detailResponse = await axios.get(detailUrl);
        return {
          name: result.name,
          previewUrl: detailResponse.data.previews["preview-hq-mp3"],
          imageUrl: detailResponse.data.images["waveform_l"],
        };
      });

      const resolvedAudios = await Promise.all(audioDetailsPromises);

      setAudioData((prevData) => [...prevData, ...resolvedAudios]);
      setHasMore(searchResponse.data.results.length === 12);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while fetching audio. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setLoading(true);
    await fetchAudio(audioQuery, nextPage);
  };

  const handleReset = () => {
    setAudioQuery("");
    setAudioData([]);
    setCurrentPage(1);
    setHasMore(false);
  };

  const handleDownloadAudio = (url, filename) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = objectURL;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(objectURL);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Audio Downloaded Successfully.",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to Download Audio. Please Try Again.",
        });
      });
  };

  return (
    <div className="right-container">
      <AnimationSpinner show={loading} />
      <div className="container h-75">
        <div className="row">
          <div className="col-sm-12">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card-container">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-end">
                        <div className="mb-4 border-bottom dashed">
                          <div className="d-flex justify-content-between align-items-center">
                            <h4 className="mb-4">AI Stock Audio Generator</h4>
                            <Link to={'/ai-stock'}> <Button><IoMdArrowRoundBack /></Button></Link>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-4 mt-4">
                          <label htmlFor="audioKeyword" className="mb-2">
                            Enter Keyword Here
                          </label>
                          <input
                            name="audioKeyword"
                            type="search"
                            className="form-control rounded-3"
                            id="audioKeyword"
                            placeholder="Enter keyword..."
                            required
                            value={audioQuery}
                            onChange={(e) => setAudioQuery(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex align-items-center justify-content-end mb-2 gap-2">
                          <Button onClick={handleAudioSearch} className="btn btn-primary fw-bold">
                            Generate
                          </Button>
                          <Button onClick={handleReset} className="btn btn-secondary fw-bold">
                            Reset
                          </Button>
                        </div>
                      </div>
                      {audioData.length > 0 && (
                        <div className="row mt-4">
                          <div className="col-lg-12">
                            <div className="card-header">

                              <div className="d-flex justify-content-between align-items-center pb-2">
                                <h4 className="card-title">Generated Audios</h4>
                                <Link to={'/ai-stock'}> <Button><IoMdArrowRoundBack /></Button></Link>
                              </div>
                            </div>
                            <div className="row align-items-center justify-content-center mt-4">
                              {audioData.map((audio, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                  <div className="ai-audio-box">
                                    <div className="ai-media">
                                      <img src={audio.imageUrl} alt={audio.name} />
                                      <div className="icon-box">
                                        <button
                                          type="button"
                                          className="btn btn-primary ai-stock-audio-download-button"
                                          onClick={() => handleDownloadAudio(audio.previewUrl, audio.name)}
                                        >
                                          <FaDownload />
                                        </button>
                                      </div>
                                    </div>
                                    <audio controls>
                                      <source src={audio.previewUrl} type="audio/mp3" />
                                    </audio>
                                    <div className="ai-image-name text-center text-dark">
                                      <div>{truncateTextByCharacters(audio.name, 30)}</div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {hasMore && (
                              <div className="text-start mt-3">
                                <Button
                                  className="btn btn-primary"
                                  onClick={handleLoadMore}
                                >
                                  Load More...
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiStockAudios;

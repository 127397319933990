import React, { useState, useContext } from "react";
import { IoIosArrowBack, IoIosSearch, IoIosArrowForward } from "react-icons/io";
import GetToken from '../../GetToken'
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import Swal from "sweetalert2";


const UploadPage = ({ base_url }) => {
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoPreview, setVideoPreview] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [inputKey, setInputKey] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const navigate = useNavigate();
  const { setVideoData } = useContext(AppContext)
  // Handle re-upload (clear the current file)
  const handleReupload = () => {
    setVideoFile(null);
    setVideoPreview("");
    setIsSelected(false);
    setInputKey((prev) => prev + 1)
  };

  // Navigate back to the previous page
  const handleGoingBack = (e) => {
    e.preventDefault();
    navigate("/ai-video-clone");
  };

  // Handle file upload and preview
  const handleFileChange = (e) => {
    handleReupload();
    const file = e.target.files[0];
    if (file) {
      setVideoFile(file);
      setVideoPreview(URL.createObjectURL(file));
      setIsSelected(true);
    }
  };

  const handleSubmit = async () => {
    if (isSubmitted) {                //if form is just submitted user cant submit again within 2 second 
      return;
    }
    if (!firstSubmit) {
      setFirstSubmit(true); // if user is not submitted even once we will mark first submission  
    }
    try {
      const token = GetToken();
      if (!token) {
        handleInvalidToken(navigate);
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("url", videoFile);
      formData.append("type", 'upload');
      formData.append("token", token);
      formData.append("action", 'videoClone');
      const response = await axios.post(
        `${base_url}api/user/upload/video_clone`,
        formData
      );
      if (response.data.status === true) {
        setLoading(false);
        setVideoData((data) => (
          { ...data, 
            videoId:Date.now(),
            url: response.data.video_url, 
            title: response.data.title, 
            source: response.data.source, 
            type: 'mp4',
						description: description
           }
        ))
        navigate("/ai-video-clone/preview-video/");
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          error.message ||
          "Something went wrong. Please try again....",
      });
    }
  }
  return (
    <div className="right-container video-search-page">
      <AnimationSpinner show={loading} />
      <div className="container h-75">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-10 mt-4">
            <h2 className="mb-4">Upload your video</h2>
            <div className="px-4 pt-2 pb-4 border rounded">
              <div className="w-100 px-4 mb-4 mt-4 d-flex flex-column gap-2 position-relative">
                <div className="w-100 px-4 mb-4 mt-4 d-flex flex-column gap-2 position-relative">
                  <label
                    htmlFor="video-search"
                    className="fs-6 cursor-pointer mb-1"
                  >
                    Enter Video Title
                  </label>
                  <input
                    className="form-control video-search-input-container"
                    type="text"
                    name="video-search"
                    id="video-search"
                    area-label="Enter Video Title"
                    placeholder="Enter title for video..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="w-100 px-4 mb-4 d-flex flex-column gap-2 position-relative">
								<label
									htmlFor="video-description"
									className="fs-6 cursor-pointer mb-1"
								>
									Enter Video Description
								</label>
								<div style={{ maxWidth: '100%', maxHeight: '200px', overflow: 'hidden' }}>
									<textarea
										rows="6"
										style={{ height: '100px' }}
										className="form-control video-search-input-container"
										type="text"
										area-label="Enter Video Description"
										placeholder="Enter description for video..."
										value={description} 
										onChange={(e)=>setDescription(e.target.value)}
										/>
								</div>
							</div>
                {!isSelected &&
                  <div className="w-100 px-4 mb-4 d-flex flex-column gap-2 position-relative">
                    <label htmlFor="video-upload" className="fs-6 cursor-pointer mb-1">
                      Upload your video
                    </label>
                    <input
                      className="form-control video-upload-input"
                      type="file"
                      id="video-upload"
                      accept="video/*"
                      onChange={handleFileChange}
                      key={inputKey}
                    />
                  </div>}
              </div>

              {/* Video Preview */}
              {videoPreview && (
                <div className="video-preview-container w-100 px-4 mb-4 mt-4">
                  <div className=" w-100 px-4 mb-4 mt-4">
                    <h5 className="mb-4">Video Preview</h5>
                    <video width="100%" controls>
                      <source src={videoPreview} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <button onClick={handleReupload} className="btn btn-secondary mt-2">
                      Re-upload Video
                    </button>
                  </div>
                </div>
              )}

              <div className="btn-container w-100 d-flex justify-content-end gap-2 align-items-center">
                <button onClick={handleGoingBack} className="btn btn-dark px-3 py-1">
                  <IoIosArrowBack />
                  {"  "}Back
                </button>
                {isSelected && (
                  <button onClick={handleSubmit} className="btn btn-dark px-3 py-1">
                    Continue{"  "}
                    <IoIosArrowForward />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPage;

import React, { useState, useContext ,useEffect} from "react";
import { AppContext } from '../../context/AppContext';
import "./Header.styles.css";
import { Link, useNavigate } from "react-router-dom";

// static assests
import SmallLogo from "../../assests/images/dark-logo.png";
import logoFavicon from "../../assests/images/Icon.png";

// react-icons
import { MdOutlineMenuOpen, MdOutlineMenu } from "react-icons/md";
import { FaShieldAlt } from "react-icons/fa";

// material ui icons and component
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Logout from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import styles from "./Header.module.css";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";

const Header = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [impersonate, setImpersonate] = useState('');
	const username = localStorage.getItem("username") ?? "User";
	const avatar = localStorage.getItem("avatar") ?? null;
	const navigate = useNavigate();
	useEffect(() => {
		setImpersonate(localStorage.getItem('impersonate'));
	}, []);
	const open = Boolean(anchorEl);
	const handleProfileClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleProfileClose = () => {
		setAnchorEl(null);
	};
	const { isSidebarOpen, toggleSidebar, base_url, clearPermissions } = useContext(AppContext);
	const handleLogout = () => {
		Swal.fire({
			title: "Are you sure you want to log out?",
			icon: "warning",
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: "Yes, log me out",
			// cancelButtonText: "Cancel",
			denyButtonText: "No, stay logged in",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"Logged out",
					"You have been successfully logged out.",
					"success"
				);
				clearPermissions();
				handleInvalidToken(navigate);
			} else if (result.isDenied) {
				Swal.fire("Stayed logged in", "You chose to stay logged in.", "info");
			}
		});
	};

	const StopImpersonate = () => {
		clearPermissions();
		localStorage.clear();
		window.location.href = 'https://clonebuddyai.com/api/admin/users';
	};

	return (
		<header className="header d-flex align-items-center shadow-sm">
			<div className="container-fluid w-100">
				<div className="row">
					<div className="col-lg-12">
						<div className="d-flex align-items-center">
							{/* logo-box */}
							<div className="d-md-block part1 logo-div">
								<Link to="/" className="logo">
									<img src={SmallLogo} alt="Logo" />
								</Link>

								<Link to="/" className="logo-favicon">
									<img src={logoFavicon} alt="favicon" />
								</Link>
							</div>

							<div className="header-right-area">
								{/* header-title and description */}
								<div className="header-description-box">
									<div className="header-description part4">
										<h4 className="mb-0 header-title">Hello {username}</h4>
										{/* <p className="mb-0">Lorem ipsum dolor sit amet.</p> */}
									</div>

									{(impersonate && impersonate == 'admin-mode') && (
										<button onClick={StopImpersonate}>impersonate</button>
									)}

								</div>

								<div className="right-inner-content ">
									{/* header profile icon */}
									<div className="d-flex align-items-center justify-content-end">
										<div className="myAccWrapper">
											<Button className="myAcc d-flex align-items-center">
												<div
													className="userImg d-flex align-content-center justify-content-center overflow-hidden "
													onClick={handleProfileClick}
												>
													<div className="rounded-circle d-flex align-content-center justify-content-center">
														<img
															src={base_url + "uploads/users/" + avatar}
															alt="User Image"
														/>
													</div>
												</div>

												<Menu
													anchorEl={anchorEl}
													id="account-menu"
													open={open}
													onClose={handleProfileClose}
													onClick={handleProfileClose}
													slotProps={{
														paper: {
															elevation: 0,
															sx: {
																overflow: "visible",
																filter:
																	"drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
																mt: 2,
																"& .MuiAvatar-root": {
																	width: 32,
																	height: 32,
																	ml: -0.5,
																	mr: 1,
																},
																"&::before": {
																	content: '""',
																	display: "block",
																	position: "absolute",
																	top: 0,
																	right: 14,
																	width: 10,
																	height: 10,
																	bgcolor: "background.paper",
																	transform: "translateY(-50%) rotate(45deg)",
																	zIndex: 0,
																},
															},
														},
													}}
													transformOrigin={{
														horizontal: "right",
														vertical: "top",
													}}
													anchorOrigin={{
														horizontal: "right",
														vertical: "bottom",
													}}
												>
													<Link to="/profile" className="w-100 user-profile">
														<MenuItem onClick={handleProfileClose}>
															<ListItemIcon>
																<PersonAdd />
															</ListItemIcon>
															Profile
														</MenuItem>
													</Link>

													<Link to="/pass-reset" className="w-100 user-profile">
														<MenuItem onClick={handleProfileClose}>
															<ListItemIcon>
																<FaShieldAlt />
															</ListItemIcon>
															Reset Password
														</MenuItem>
													</Link>
													<Button onClick={handleLogout} className="w-100 user-profile logout-btn ">
														<MenuItem
															onClick={handleProfileClose}
															className="logout "
														>
															<ListItemIcon className="logout">
																<Logout />
															</ListItemIcon>
															Logout
														</MenuItem>
													</Button>

												</Menu>
											</Button>
										</div>
									</div>

									{/* sidebar toggle button */}
									<div className="d-sm-flex d-lg-none d-md-block align-items-center" onClick={toggleSidebar}>
										<Button className="rounded-circle" >
											{isSidebarOpen ? (<MdOutlineMenuOpen />) : (<MdOutlineMenu />)}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;

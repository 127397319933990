import React, { useState, useEffect } from "react";
import AnimationSpinner from "../../../AnimationSpinner";
import emptyIcon from "../../../assests/images/emptyIcon.png";
import { Link } from "react-router-dom";
import { Card, Button, Modal, Row, Col, Pagination, Form, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../../GetToken";
import { handleInvalidToken } from "../../../AuthUtils";
import { useNavigate } from "react-router-dom";
import { MdOutlineCreateNewFolder } from "react-icons/md";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaCode } from 'react-icons/fa'; // Import the icon
import { truncateTextByWords } from "../../../util";
const ManageSite = ({ base_url }) => {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [tempToken, setTempToken] = useState(null);  // Store tempToken
    const [userSites, setUserSites] = useState([]);
    const [showEmbedCodeModel, setShowEmbedCodeModel] = useState(false);
    const handleEmbedCodeModelClose = () => setShowEmbedCodeModel(false);
    const [thirdPartyScript, setThirdPartyScript] = useState(""); // To hold the third party script value
    const [currentItem, setCurrentItem] = useState(null); // To store the current item when Embed Code is clicked
    const navigate = useNavigate();

    const DESCRIPTION_LIMIT = 500;
    const TITLE_LIMIT = 100;

    const fetchTemplates = async () => {
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            setLoading(true)

            const formData = new FormData();
            formData.append("action", "user_templates_api");
            formData.append("token", token);
            formData.append("page", currentPage);
            formData.append("limit", itemsPerPage);

            const response = await axios.post(
                `${base_url}api/user/user_templates_api`,
                formData
            );
            console.log(response.data.data.user_template_list.domain_data);
            if (response.data.status) {
                setLoading(false);
                setItems(response.data.data.user_template_list);
                setTotalItems(response.data.data.pagination.total);
                setTempToken(response.data.tempToken);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.message || "Failed to fetch data.",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };
    useEffect(() => {
        fetchTemplates();
    }, [currentPage, itemsPerPage]);


    const handleEditClick = (itemId) => {
        const editUrl = `https://clonebuddyai.com/website/design/${itemId}?token=${tempToken}`;
        window.open(editUrl, "_blank");
    };

    const handleSiteView = (slug, subdomain, customdomain) => {
        const viewUrl = customdomain
            ? `https://${customdomain}/api/${slug}`
            : `https://${subdomain}.clonebuddyai.com/${slug}`;

        window.open(viewUrl, "_blank");
    };

    const handleEmbedClick = (item) => {
        setCurrentItem(item);
        setThirdPartyScript(thirdPartyScript != null && thirdPartyScript.trim() !== '' ? thirdPartyScript : item.third_party_script);
        setShowEmbedCodeModel(true);
    };

    const handleSubmitScript = async () => {
        const result = await Swal.fire({
            title: "Do you want to add this script?",
            text: "Once submitted, the script will be added to your site.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, submit it!",
            cancelButtonText: "No, cancel"
        });

        if (result.isConfirmed) {
            try {
                const { custom_domain, domain_data, slug } = currentItem;
                const subdomain = domain_data.sub_domain_name;

                let valid_custom_domain = '';

                if (custom_domain && custom_domain !== '') {
                    valid_custom_domain = custom_domain;  // Assign valid custom domain
                }

                const token = GetToken();
                if (!token) {
                    handleInvalidToken(navigate);
                    return;
                }
                setLoading(true);
                const formData = new FormData();
                formData.append("action", "submit_third_party_script");
                formData.append("token", token);
                formData.append("slug", slug);
                formData.append("subdomain", subdomain);
                formData.append("custom_domain", valid_custom_domain);
                formData.append("third_party_script", thirdPartyScript);  // Include third-party script input

                const response = await axios.post(
                    `${base_url}api/user/submit_third_party_script`,
                    formData
                );

                if (response.data.status === true) {
                    setLoading(false);
                    Swal.fire("Success!", response.data.message, "success");
                    setThirdPartyScript(response.data.updated_script || thirdPartyScript);
                    setTimeout(() => {
                        setShowEmbedCodeModel(false);
                        navigate('/manage-site');
                    }, 3000); // 3 seconds delay
                } else {
                    setLoading(false);
                    Swal.fire("Error", response.data.message, "error");
                }
            } catch (err) {
                setLoading(false);
                Swal.fire("Error", "Failed to submit the third-party script.", "error");
            }

        } else {
            // If user canceled, just close the modal
            setShowEmbedCodeModel(false);
        }
    };

    const handleSiteDelete = async (id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this item!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                const token = GetToken();
                if (!token) {
                    handleInvalidToken(navigate);
                    return;
                }
                setLoading(true);
                const formData = new FormData();
                formData.append("action", "user_template_delete_api");
                formData.append("token", token);
                formData.append("site_ids", id);
                const response = await axios.post(
                    `${base_url}api/user/user_template_delete_api`,
                    formData
                );

                if (response.data.status === true) {
                    setLoading(false);
                    // setUserSites(userSites.filter((item) => item.id !== id));
                    fetchTemplates();
                    Swal.fire("Deleted!", "Your Site has been deleted.", "success");
                } else {
                    setLoading(false);
                    if (response.data.reset === true) {
                        handleInvalidToken(navigate);
                        return;
                    }
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: response.data.message,
                    });
                }
            } catch (error) {
                setLoading(false);
                Swal.fire("Error", "Failed to delete Site", "error");
            }
        }
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className="right-container">
            <AnimationSpinner show={loading} />
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-card">
                        <div className="form-body">
                            <div className="head-part w-100">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <h4 className="title"> Manage Clone Sites</h4>
                                    </div>
                                </div>
                            </div>


                            {items.length > 0 ? (
                                <Row>
                                    {items.map((item) => {
                                        return (
                                            <Col
                                                key={item.id}
                                                className="col-lg-4 col-md-4 mb-4"
                                            >
                                                <div className="site-card shadow-md">
                                                    <div className="site-card-image-container">
                                                        <Card.Img
                                                            variant="top"
                                                            src={item.storage === 'aws'
                                                                ? `https://clonebuddyai.s3.eu-north-1.amazonaws.com/${item.thumbnail_path}`
                                                                : `${base_url}${item.thumbnail_path}`}
                                                        />
                                                    </div>
                                                    <Card.Body className="px-3 pt-2 pb-3">
                                                        <Card.Title className="card-title border-0 pb-0">
                                                            {truncateTextByWords(item.title, TITLE_LIMIT)}
                                                        </Card.Title>
                                                        <div className="compay-card-btn-container mt-2 w-100 d-flex justify-content-start align-items-center gap-2">
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => handleEditClick(item.id)}
                                                            >
                                                                <FaRegEdit />
                                                            </Button>
                                                            <Button
                                                                variant="info"
                                                                onClick={() => handleSiteDelete(item.id)}
                                                            >
                                                                <MdDelete />
                                                            </Button>
                                                            {item.slug && (
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={() => handleSiteView(item.slug, item.domain_data.sub_domain_name, item.domain_data.custom_domain)}
                                                                    className="ml-2"
                                                                >
                                                                    <FaEye />
                                                                </Button>
                                                            )}
                                                            {/* Embed code button with icon */}
                                                            {item.slug && (
                                                                <Button
                                                                    variant="secondary"
                                                                    onClick={() => handleEmbedClick(item)}
                                                                    className="ml-2"
                                                                >
                                                                    <FaCode /> {/* Icon for Embed Code */}
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            ) : (
                                <div className="row mt-4 mb-5">
                                    <div className="col-lg-5 col-md-8 mx-auto">
                                        <div className="empty-box">
                                            <div className="card-body">
                                                <div className="list-empty-box">
                                                    <div className="icon">
                                                        <img src={emptyIcon} alt="No data available" />
                                                    </div>
                                                    <p>No data available</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
                                <Pagination className="mb-0">
                                    <Pagination.Prev
                                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                        disabled={currentPage === 1}
                                    />
                                    {[...Array(totalPages)].slice(Math.floor((currentPage - 1) / 10) * 10, Math.floor((currentPage - 1) / 10) * 10 + 10).map((_, index) => (
                                        <Pagination.Item
                                            key={index + 1 + Math.floor((currentPage - 1) / 10) * 10}
                                            active={index + 1 + Math.floor((currentPage - 1) / 10) * 10 === currentPage}
                                            onClick={() => setCurrentPage(index + 1 + Math.floor((currentPage - 1) / 10) * 10)}
                                        >
                                            {index + 1 + Math.floor((currentPage - 1) / 10) * 10}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                    />
                                </Pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Embed Code Modal */}
            <Modal show={showEmbedCodeModel} onHide={handleEmbedCodeModelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Third Party Script</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        value={thirdPartyScript}
                        onChange={(e) => setThirdPartyScript(e.target.value)} // Update script value
                        placeholder="Enter third-party script here"
                        className="form-control"
                        rows="5"
                    />
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handleSubmitScript}
                    >
                        Submit Script
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEmbedCodeModelClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ManageSite

import React, { useState, useContext, useRef, useEffect } from 'react'
import AnimationSpinner from '../../AnimationSpinner'
import { AppContext } from '../../context/AppContext'
import GetToken from '../../GetToken';
import { handleInvalidToken } from '../../AuthUtils';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import emptyIcon from '../../assests/images/emptyIcon.png';
import { IoMdSearch } from "react-icons/io";


// react icons import
import { RiText, RiNewsLine } from "react-icons/ri";
import { FaImage } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";
import { IoIosUndo, IoIosRedo, IoIosSearch, IoIosImages } from "react-icons/io";
import { ImEmbed2 } from "react-icons/im";
import { GiExitDoor } from "react-icons/gi";
import { MdOutlineAdsClick, MdCopyAll, MdPreview, MdDeleteForever, MdOutlineEditNote, MdOutlineTextFields, MdDownload } from "react-icons/md";
import { TbImageInPicture } from "react-icons/tb";


// importing model & buttons from bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import { BaseElement, EditElement } from './elementFunctions';
import { Rnd } from 'react-rnd';  // drag and drop lib



const PreviewVideo = ({ base_url }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { videoData } = useContext(AppContext);

    // state and functions for video editing.....
    const [elementsState, setElementsState] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false)
    const [selectedElement, setSelectedElement] = useState(null);
    const [HtmlElements, setHtmlElements] = useState([])
    const videoRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);

    // ===========================states for stock model===========================
    const [showStockModel, setShowStockModel] = useState(false);
    const [stockImagedData, setStockImagedData] = useState([]);
    const [stockImageSelected, setStockImageSelected] = useState(false)
    const [stockImage, setStockImage] = useState({})
    const [stockQuery, setStockQuery] = useState("");
    const [orientation, setOrientation] = useState("all");
    const [imageType, setImageType] = useState("all");
    const [currentPage, setCurrentPage] = useState(1);

    // ===========================states for subscribe model===========================
    const [showSubscribeModel, setShowSubscribeModel] = useState(false);
    const [subscriberName, setSubscriberName] = useState('');
    const [subscriberEmail, setSubscriberEmail] = useState('');
    const [subscriptionErrors, setSubscriptionErrors] = useState({});

    //========================states for features=================================
    // states for undo and redo functionalitites
    const [actions, setActions] = useState([])
    const [redolist, setRedolist] = useState([])
    const [saved, setSaved] = useState(false)

    // sates responsible for embed model
    const [embedCode, setEmbedCode] = useState("")
    const [showEmbedModel, setEmbedModelShow] = useState(false);


    // ============================Elements Tabs=====================================
    const elementsTab1 = [
        { name: 'Text', icon: <RiText />, type: 'text' },
        { name: 'Button', icon: <RiNewsLine />, type: 'button' },
        { name: 'Image', icon: <FaImage />, type: 'image' },
        { name: 'Stock Image', icon: <IoIosImages />, type: 'stock_image' },
        { name: 'Text Watermark', icon: <MdOutlineTextFields />, type: 'text_watermark' },
        { name: 'Image Watermark', icon: <TbImageInPicture />, type: 'image_watermark' },
        { name: 'Subscribe', icon: <MdOutlineAdsClick />, type: 'subscribe' },
        { name: 'Share', icon: <IoShareSocial />, type: 'share' },
    ]
    const elementsTab2 = [
        { name: 'Copy link', icon: <MdCopyAll />, type: 'copy_link' },
        { name: 'Undo', icon: <IoIosUndo />, type: 'undo' },
        { name: 'Redo', icon: <IoIosRedo />, type: 'redo' },
        { name: 'Embed', icon: <ImEmbed2 />, type: 'embed' },
        // { name: 'Preview', icon: <MdPreview />, type: 'preview' },
        { name: 'Exit', icon: <GiExitDoor />, type: 'exit' },
    ]
    // ==================================functions for handling elements add and edit acc to flow==================

    // click functions for getting into editor properties and cancel editing for element ....................
    const onElementAddClick = (element) => {
        if (element.type == "stock_image") {
            if (stockImageSelected) {

                const element = {
                    ...stockImage,
                    type: 'stock_image'
                }
                setSelectedElement(element);
                setIsAdding(true)
                setStockImageSelected(false);
            } else {
                stockModelShow();
            }
        } else if (element.type === 'share') {
            console.log("share")
        } else if (element.type === 'subscribe') {
            SubscribeModelShow()
        } else {
            setIsAdding(true)
            setSelectedElement(element);
        }
    }
    const onElementEditClick = (element) => {
        setIsEditing(true)
        setSelectedElement(element);
    }
    const cancelEdit = () => {
        setSelectedElement(null);
        setIsEditing(false)
        setIsAdding(false)
    }

    // element functionalities start time calculations from the provided string formate
    const handleStartTimeCalculation = (time) => {
        return Number(time.split(':').reduce((acc, curr, i) => acc + parseInt(curr, 10) * Math.pow(60, 2 - i), 0))
    };

    // button click functionalitie bugs fixes by this function
    const handleButtonClick = (element) => {

        if (!isDragging && !isResizing) {
            window.open(element.link, "_blank");
        }
        setIsDragging(false);
        setIsResizing(false);
    }

    // core functions for editing elements and updating elements properties................................
    const handleAddElement = (type, data) => {
        if (data.startTime.length < 8) {
            Swal.fire({
                icon: 'error',
                title: 'Start time must be in format HH:MM:SS',
                text: 'Please correct the start time',
                confirmButtonText: 'Okay'
            })
            return
        } else if (data.duration <= 0) {
            Swal.fire({
                icon: 'error',
                title: 'Duration must be greater than 0',
                text: 'Please correct the duration',
                confirmButtonText: 'Okay'
            })
            return
        }
        if (isEditing) return;
        let newElement;
        const startTime = handleStartTimeCalculation(data.startTime)
        if (type === 'text') {
            newElement = {
                videoId: videoData.videoId,
                id: Date.now(),
                type: type,
                content: data?.content || "default text",
                x: 50,
                y: 50,
                width: 100,
                height: 30,
                fontSize: data?.fontSize || 16,
                backgroundColor: data?.backgroundColor || "#000",
                color: data?.color || "#fff",
                startTime: startTime || 0,
                duration: data.duration,
                borderRadius: data?.borderRadius ?? '8px',
            };
            setIsAdding(false)
        } else if (type === 'text_watermark') {
            newElement = {
                videoId: videoData.videoId,
                id: Date.now(),
                type: type,
                content: data?.content || "default text",
                x: 50,
                y: 50,
                width: 100,
                height: 30,
                opacity: 0.4,
                borderRadius: data?.borderRadius ?? '8px',
                fontSize: data?.fontSize || 16,
                color: data?.color || "#fff",
                startTime: startTime || 0,
                duration: data.duration,
            };
            setIsAdding(false)
        }
        else if (type === 'image') {
            newElement = {
                videoId: videoData.videoId,
                id: Date.now(),
                type: type,
                content: data?.imageAlt || "dummy image",
                x: 50,
                y: 50,
                width: 100,
                height: 100,
                altText: data?.imageAlt || "dummy image",
                imagePreview: data?.imagePreview,
                borderRadius: data?.borderRadius ?? '8px',
                startTime: startTime || 0,
                duration: data.duration,
                file: data.image
            };
            setIsAdding(false)

        } else if (type === 'image_watermark') {
            newElement = {
                videoId: videoData.videoId,
                id: Date.now(),
                type: type,
                content: data?.imageAlt || "dummy image",
                x: 50,
                y: 50,
                width: 100,
                height: 100,
                opacity: 0.4,
                altText: data?.imageAlt || "dummy image",
                imagePreview: data?.imagePreview,
                borderRadius: data?.borderRadius ?? '8px',
                startTime: startTime || 0,
                duration: data.duration,
                file: data.image
            };
            setIsAdding(false)

        }
        else if (type === 'button') {
            newElement = {
                videoId: videoData.videoId,
                id: Date.now(),
                type: type,
                content: data.text || "default text",
                link: data.link || "#",
                backgroundColor: data?.backgroundColor || "#000",
                color: data?.color || "#fff",
                x: 50,
                y: 50,
                width: 100,
                height: 30,
                fontSize: "16px",
                borderRadius: data?.borderRadius ?? '4px',
                startTime: startTime || 0,
                duration: data.duration,
            };
            setIsAdding(false)
        }
        else if (type === 'stock_image') {
            newElement = {
                videoId: videoData.videoId,
                id: Date.now(),
                type: 'stock_image',
                content: data?.imageAlt || "dummy image",
                x: 50,
                y: 50,
                width: 100,
                height: 100,
                altText: data?.imageAlt || "dummy image",
                previewURL: data?.previewURL,
                borderRadius: data?.borderRadius ?? '8px',
                startTime: startTime || 0,
                duration: data.duration,
            };
            setIsAdding(false)
        }
        setElementsState([...elementsState, newElement]);
        setActions((prev) => (
            [...prev,
            {
                ['category']: "addElement",
                ['element']: newElement,
            }
            ]
        ))
        setIsAdding(false)
    };
    const handleDeleteElement = async (element) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this item!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            const updatedStates = elementsState.filter(item => item.id !== element.id)
            setElementsState(updatedStates)
        }
    }
    const handleEditProperties = (id, updatedProperties) => {
        if (isAdding) return;
        if (updatedProperties.startTime.length < 8) {
            Swal.fire({
                icon: 'error',
                title: 'Start time must be in format HH:MM:SS',
                text: 'Please correct the start time',
                confirmButtonText: 'Okay'
            })
            return
        } else if (updatedProperties.duration <= 0) {
            Swal.fire({
                icon: 'error',
                title: 'Duration must be greater than 0',
                text: 'Please correct the duration',
                confirmButtonText: 'Okay'
            })
            return
        }
        const startTime = handleStartTimeCalculation(updatedProperties.startTime)
        const editedProperties = { ...updatedProperties, startTime: startTime }
        setElementsState((prevState) =>
            prevState.map((element) =>
                element.id === id ? { ...element, ...editedProperties } : element
            )
        );
        setActions((prev) => (
            [...prev,
            {
                ['category']: "edit element",
                ['element']: { ...updatedProperties },
            }
            ]
        ))
        setSelectedElement(null);
        setIsEditing(false);

    };


    // function responsible for overlaying the element into video ............................... 
    const renderOverlays = () => {
        return (
            elementsState
                .map((element) => (
                    <Rnd key={element.id}
                        default={{
                            x: element.x,
                            y: element.y,
                            width: element.width,
                            height: element.height,

                        }}
                        bounds="parent"
                        enableResizing={{
                            bottomRight: true,
                        }}
                        onDragStart={() => setIsDragging(true)}
                        onDragStop={(e, data) => {

                            const percentageY = (data.y / videoRef.current.offsetHeight) * 100
                            const percentageX = (data.x / videoRef.current.offsetWidth) * 100
                            setElementsState((prevState) =>
                                prevState.map((el) =>
                                    el.id === element.id
                                        ? { ...el, x: percentageX, y: percentageY }
                                        : el
                                )
                            );
                            setIsDragging(false)
                        }}
                        onResizeStart={() => setIsResizing(true)}
                        onResizeStop={(e, direction, ref, delta, position) => {

                            setElementsState((prevState) =>
                                prevState.map((el) =>
                                    el.id === element.id
                                        ? {
                                            ...el,
                                            width: parseInt(ref.style.width, 10),
                                            height: parseInt(ref.style.height, 10),
                                            ...position,
                                        }
                                        : el
                                )
                            );
                            setIsResizing(false)
                        }}
                    >

                        {element.type === 'text' && (
                            <div
                                style={{
                                    fontSize: element.fontSize + "px",
                                    color: element.color,
                                    backgroundColor: element.backgroundColor,
                                    padding: "2px 10px",
                                    borderRadius: "4px",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                {element.content || 'Default text'}
                            </div>
                        )}
                        {element.type === 'text_watermark' && (
                            <div
                                style={{
                                    fontSize: element.fontSize + "px",
                                    color: element.color,
                                    opacity: element.opacity,
                                    padding: "2px 10px",
                                    borderRadius: "4px",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                {element.content || 'Default text'}
                            </div>
                        )}
                        {element.type === 'image' && (
                            <img
                                src={element.imagePreview}
                                alt="Custom"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: element.borderRadius + "px"
                                }}
                            />
                        )}
                        {element.type === 'image_watermark' && (
                            <img
                                src={element.imagePreview}
                                alt="Custom"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    opacity: element.opacity,
                                    borderRadius: element.borderRadius + "px"
                                }}
                            />
                        )}
                        {element.type === 'stock_image' && (
                            <img
                                src={element.previewURL}
                                alt={element.altText}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: element.borderRadius + "px"
                                }}
                            />
                        )}
                        {element.type === 'button' && (
                            
                            <button className='btn '
                                style={{
                                    backgroundColor: element.backgroundColor,
                                    color: element.color,
                                    padding: "5px 10px",
                                    borderRadius: "8px",
                                    cursor: "pointer"
                                }}
                                onClick={() => handleButtonClick(element)}
                            >
                                {element.content}
                            </button>
                        )}
                    </Rnd>
                ))
        );
    };

    // function to save the element configuration after editing ...............................
    const handleSave = (vSlug) => {
        const config = JSON.stringify(elementsState);
        const imageData = elementsState.map((element, index) => {
            if (element.type === 'image') {
                return {
                    id: element.id,
                    name: index + 1
                }
            }
        })
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            setLoading(true);

            const formData = new FormData();
            formData.append("token", token);
            formData.append("editing_data", config);
            formData.append("imageData", imageData);
            formData.append("video_title", videoData.title);
            formData.append("video_description", videoData.description);
            formData.append("token", token);
            formData.append("slug", vSlug);
            formData.append("action", 'video_preview_x_data');
            let imageCounter = 1;
            elementsState.forEach((element) => {
                if (element.type === 'image' || 'image_watermark') {
                    const variableName = `image${imageCounter}`;
                    formData.append(variableName, element.file);
                    imageCounter++;
                }
            });
            axios
                .post(`${base_url}api/user/video/preview/x-data`, formData)
                .then((response) => {
                    if (response.data.status === true) {
                        setLoading(false);
                        setSaved(true)
                        Swal.fire({
                            icon: "success",
                            title: "Saved",
                            text: "Your changes have been saved successfully.",
                            showCancelButton: true,
                            confirmButtonText: "Manage Videos",
                            cancelButtonText: "Preview",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/edited-videos') // navigate to manage videos
                                setElementsState([])
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                window.open(`${base_url}player-pre/${vSlug}`, "_blank");  //navigate to preview video
                                setElementsState([])
                            }
                        });
                    } else {
                        setLoading(false);
                        if (response.data.reset === true) {
                            handleInvalidToken(navigate);
                            return;
                        }
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: response.data.message,
                        });
                    }
                });

        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };

    // function to handle Features 
    const handleFeatures = (featureName) => {
        switch (featureName) {
            case 'exit':
                navigate('/ai-video-clone')
                setElementsState([])
                break;
            case 'embed':
                handleEmbedModelShow()
                break;
            case 'copy_link':
                copyLink();
                break;
            case 'undo':
                // handleFeature5();
                break;
            case 'redo':
                // handleFeature5();
                break;
            default:
                console.warn('Invalid feature name');
        }
    };

    //================================================= feature functions=====================================================
    // handling embed feature
    const handleEmbedModelClose = () => setEmbedModelShow(false);

    const handleEmbedModelShow = () => {
        setEmbedCode(`<iframe uniquetitle="video" width="100%" height="400" className="demo-video" src=${`${base_url}player-pre/${videoData.slug}`} frameBorder="0" allowFullScreen>  </iframe>`)
        setEmbedModelShow(true);
    }
    const handleEmbedModelCopy = () => {
        navigator.clipboard.writeText(embedCode)
        Swal.fire({
            icon: "success",
            title: "Copied",
            text: "Embed code has been copied to clipboard.",
        });
        setEmbedModelShow(false);
    };

    // function handling copy link feature
    const copyLink = () => {
        navigator.clipboard.writeText(`${base_url}player-pre/${videoData.slug}`)
        Swal.fire({
            icon: "success",
            title: "Copied",
            text: "Link has been copied to clipboard.",
        });
    }
    // ================================== end of functions for handling elements add and edit acc to flow==================
    useEffect(() => {
        const html = renderOverlays()
        setHtmlElements(html)
    }, [elementsState])


    // ======================================element model functions for stock and subscribe =========================

    // subscription model functions----------------------------------------------------------------
    const SubscribeModelClose = () => setShowSubscribeModel(false);

    const SubscribeModelShow = () => {
        setShowSubscribeModel(true);
    }

    const validateSubscription = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!subscriberName.trim()) {
            newErrors.subscriberName = 'Name is required.';
        } else if (subscriberName.trim().length < 3) {
            newErrors.subscriberName = 'Name must be at least 3 characters long.';
        }

        if (!subscriberEmail.trim()) {
            newErrors.subscriberEmail = 'Email is required.';
        } else if (!emailRegex.test(subscriberEmail)) {
            newErrors.subscriberEmail = 'Enter a valid email address.';
        }

        setSubscriptionErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubscribe = () => {
        if (validateSubscription()) {
            console.log('Subscribed with:', { subscriberName, subscriberEmail });
            setSubscriberName('');
            setSubscriberEmail('');
            setSubscriptionErrors({});
            SubscribeModelClose();
        }
    };

    // stock model functions----------------------------------------------------------------
    const stockModelClose = () => setShowStockModel(false);

    const stockModelShow = () => {
        setShowStockModel(true);
    }

    const handleStockImageSearch = async (e, page = 1) => {
        e?.preventDefault();
        if (!stockQuery.trim()) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter a keyword to generate images",
            });
            return;
        }

        setLoading(true);
        if (page === 1) setStockImagedData([]);

        try {
            const response = await fetch(
                `https://pixabay.com/api/?key=43529301-48bb67a4a3176c73ad9bd51f3&q=${stockQuery}&image_type=${imageType}&orientation=${orientation}&page=${page}&per_page=12`
            );
            const data = await response.json();

            if (data.total === 0) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Result not found! Please try again with different keywords.",
                });
                return;
            }

            setStockImagedData((prev) => [...prev, ...data.hits]);
            setCurrentPage(page);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "An error occurred while fetching images. Please try again.",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        handleStockImageSearch(null, currentPage + 1);
    };

    const handleReset = () => {
        setStockQuery("");
        setStockImagedData([]);
        setCurrentPage(1);
    };

    const handleSelectStockImage = async (imageId) => {
        setShowStockModel(false);
        const image = stockImagedData.find((item) => item.id === imageId)
        setStockImage(image)
        setStockImageSelected(true);
        setStockQuery('null')
    }
    useEffect(() => {
        if (stockImageSelected) {
            onElementAddClick({ type: 'stock_image' });
        }
    }, [stockImageSelected]);
    // ======================================end element model functions for stock and subscribe =========================
    return (
        <>
            <div className="container h-100">
                <AnimationSpinner show={loading} />
                <div className="right-container">
                    <div className="row g-4">
                        <div className="col-lg-3">
                            <div className="p-2 border rounded element-container">
                                {!isEditing && !isAdding ? (
                                    <BaseElement elements={elementsTab1} onElementAddClick={onElementAddClick} />
                                ) : (
                                    <EditElement
                                        element={selectedElement}
                                        onAddElement={handleAddElement}
                                        onCancel={cancelEdit}
                                        handleEditProperties={handleEditProperties}
                                        isAdding={isAdding}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-8 mb-2 mx-auto d-flex flex-column gap-4 p-4 border element-container rounded">
                            <div className="title-container d-flex gap-4">
                                <h5>{videoData.title}</h5>
                            </div>
                            <div className="video-container-iframe demo-video" style={{ position: 'relative' }}>
                                <iframe
                                    ref={videoRef}
                                    uniquetitle="video"
                                    width="100%"
                                    height="400"
                                    className="demo-video"
                                    src={videoData.url}
                                    frameBorder="0"
                                    allowFullScreen
                                >
                                </iframe>
                                {HtmlElements}
                            </div>
                        </div>
                        <div className="col-1 element-container">
                            <div className="border rounded element-tab2  d-flex flex-column justify-content-center" style={{ backgroundColor: "#e2d7f559" }}>
                                {elementsTab2.map((element, index) => (
                                    <div className={`col-lg-12 mb-2 mx-auto ${index == 0 ? "mt-2" : null}`} key={index}>
                                        <div className="mx-auto cursor-pointer element rounded d-flex justify-content-center align-items-center flex-column p-2" onClick={() => handleFeatures(element.type)}>
                                            {element.icon}
                                            <p className='m-0'>{element.name}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-lg-12 mb-2 mx-auto">
                                    <div className="mx-auto cursor-pointer element rounded d-flex justify-content-center align-items-center flex-column p-2">
                                        <button className='px-2 py-1 btn btn-success' onClick={() => handleSave(videoData.slug)}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ---------------------------------------------------------Embed Model-------------------------------------------------------------------- */}
                    <Modal show={showEmbedModel} onHide={handleEmbedModelClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Copy Embed Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="embedCode" className='w-100 mb-2 '></label>
                            <div style={{ maxWidth: '100%', maxHeight: '200px', overflow: 'hidden' }}>
                                <textarea rows="6" style={{ height: '100px' }} className="form-control w-100 embed-model-input" type="text" defaultValue={embedCode} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleEmbedModelClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleEmbedModelCopy}>
                                Copy to clipboard
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* ---------------------------------------------------------Subscriber Model-------------------------------------------------------------------- */}
                    <Modal show={showSubscribeModel} onHide={SubscribeModelClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Subscriber's Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-10 mb-3 mx-auto">
                                <label htmlFor="page_title" className='w-100 mb-2 '>Full Name <span className='text-danger'>*</span></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="page_title"
                                    placeholder="Enter full name.."
                                    value={subscriberName} onChange={(e) => setSubscriberName(e.target.value)}
                                />
                                {subscriptionErrors.subscriberName && <p className="text-danger text-danger m-0 pt-1">{subscriptionErrors.subscriberName}</p>}
                            </div>
                            <div className="col-md-10 mb-3 mx-auto">
                                <label htmlFor="page_title" className='w-100 mb-2 '>Email <span className='text-danger'>*</span></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="page_title"
                                    placeholder="Enter email.."
                                    value={subscriberEmail} onChange={(e) => setSubscriberEmail(e.target.value)}
                                />
                                {subscriptionErrors.subscriberEmail && <p className="text-danger m-0 pt-1">{subscriptionErrors.subscriberEmail}</p>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={SubscribeModelClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubscribe}>
                                Subscribe
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* ---------------------------------------------------------stock Model-------------------------------------------------------------------- */}
                    <Modal className='stock_model' show={showStockModel} onHide={stockModelClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Search Stock Images</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="card-container">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row align-items-end">
                                                            <div className="col-lg-8 col-md-6 mb-4">
                                                                <label htmlFor="keyword" className="text-md-right">Enter
                                                                    Keyword Here</label>
                                                                <input
                                                                    name="keyword"
                                                                    type="search"
                                                                    className="form-control rounded-3"
                                                                    id="keyword"
                                                                    placeholder="Enter keyword..."
                                                                    required
                                                                    value={stockQuery}
                                                                    onChange={(e) => setStockQuery(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 mb-4">
                                                                <label htmlFor="orientation" className="text-md-right">Orientation
                                                                </label>
                                                                <select
                                                                    className="form-control rounded-3"
                                                                    value={orientation}
                                                                    onChange={(e) => setOrientation(e.target.value)}
                                                                >
                                                                    <option value="all" >All</option>
                                                                    <option value="horizontal" id="Horizontal">Horizontal</option>
                                                                    <option value="vertical">Vertical</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-2 col-md-6 mb-4">
                                                                <label htmlFor="image-type" className="text-md-right">Image Type</label>
                                                                <select
                                                                    className="form-control rounded-3"
                                                                    value={imageType}
                                                                    onChange={(e) => setImageType(e.target.value)}
                                                                >
                                                                    <option value="all">All</option>
                                                                    <option value="photo">Photo</option>
                                                                    <option value="illustration">Illustration</option>
                                                                    <option value="vector">Vector</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div id="show_image">
                                                                    <div className="card-header">
                                                                        <h5 className="card-title">Generated Images</h5>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12 mx-auto">
                                                                            <div className="mb-4">
                                                                                <div id="image" className="row mt-4">
                                                                                    {stockImagedData.length > 0 ? (
                                                                                        stockImagedData.map((image, index) => (
                                                                                            <div className="col-lg-3 col-md-6" key={index}>
                                                                                                <div className="vc-box p-0">
                                                                                                    <div className="vc-media">
                                                                                                        <img src={image.previewURL} alt={`Stock ${index + 1}`} />
                                                                                                        <div className="vc-btn">
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-primary"
                                                                                                                onClick={() => handleSelectStockImage(image.id)}
                                                                                                            >
                                                                                                                Choose
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : null}
                                                                                </div>
                                                                                <div className="card-footer bg-transparent px-0">
                                                                                    <button className="btn btn-primary fw-600 fs-5 rounded-3" type="button" id="loadmorebtn" onClick={handleLoadMore}>Load More....</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-primary fw-bol d-flex align-items-center justify-content-center mr-2 gap-2" onClick={handleStockImageSearch}>
                                <IoMdSearch/>
                                Generate
                            </Button>

                            <div className="d-flex align-items-center" id="reset-button-container">
                                <Button onClick={handleReset} className="btn btn-secondary fw-bol d-flex align-items-center justify-content-center">
                                    Reset
                                </Button>
                            </div>
                            <Button variant="secondary" onClick={stockModelClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className={`row mt-4 p-4 rounded border ${elementsState.length > 0 ? "" : "d-none"}`}>
                        <h4 className='border-bottom pb-2'>Added elements</h4>
                        {elementsState.length > 0 ?
                            (
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <table
                                                id="dataTable_agency_user"
                                                className="table table-striped w-100 dataTable no-footer"
                                                role="grid"
                                                aria-describedby="dataTable_agency_user_info"
                                                style={{ width: "1067px" }}
                                            >
                                                <thead className="table-head">
                                                    <tr role="row">
                                                        <th>Type</th>
                                                        <th>Content</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {elementsState.map((element, index) => (
                                                        <tr role="row" className={index % 2 == 1 ? "even" : "odd"} key={element.id}>
                                                            <td>{element.type.toUpperCase()}</td>
                                                            <td>{element.content}</td>
                                                            <td >
                                                                <div className="d-flex gap-2 justify-content-center align-items-center">
                                                                    <MdOutlineEditNote className="me-2 mb-1 p-1 rounded shadow cursor-pointer" style={{ fontSize: "32px", backgroundColor: "#fff" }} onClick={() => onElementEditClick(element)} />
                                                                    <MdDeleteForever className="me-2 mb-1 p-1 rounded shadow cursor-pointer" style={{ fontSize: "32px", backgroundColor: "#fff" }} onClick={() => handleDeleteElement(element)} />
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PreviewVideo;
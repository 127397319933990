import React, { useState, useContext } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import GetToken from '../../GetToken'
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import Swal from "sweetalert2";
import { handleInvalidToken } from "../../AuthUtils";

const YoutubePage = ({ base_url }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [firstSubmit, setFirstSubmit] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const navigate = useNavigate();
	const { setVideoData } = useContext(AppContext)

	// extrating video id from youtube url
	const extractYouTubeVideoID = (url) => {
		const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
		const match = url.match(regex);
		return match ? match[1] : null;
	};

	// handling going back
	const handleGoingBack = (e) => {
		e.preventDefault();
		navigate("/ai-video-clone");
	};

	// navigating to preview videoPage with extracted id
	const handleSubmit = async () => {
		if (isSubmitted) {                //if form is just submitted user cant submit again within 2 second 
			return;
		}
		if (!firstSubmit) {
			setFirstSubmit(true); // if user is not submitted even once we will mark first submission  
		}
		const videoId = await (extractYouTubeVideoID(searchQuery))
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("title", title);
			formData.append("description", description);
			formData.append("url", `https://www.youtube.com/embed/${videoId}`);
			formData.append("type", 'youtube');
			formData.append("token", token);
			formData.append("action", 'videoClone');
			const response = await axios.post(
				`${base_url}api/user/video_clone`,
				formData
			);
			if (response.data.status === true) {
				setLoading(false);
				setVideoData((data) => (
					{ ...data, 
						videoId:videoId,
						url: response.data.video_url, 
						title: response.data.title, 
						source: response.data.source, 
						type: 'youtube',
						description: description
					}
				))
				navigate("/ai-video-clone/preview-video/");
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	}
	return (
		<div className="right-container video-search-page">
			<AnimationSpinner show={loading} />
			<div className="container h-75">
				<div className="row h-100 justify-content-center align-items-center">
					<div className="col-10 mt-4">
						<h2 className="mb-4">Enter YouTube URL</h2>
						<div className="px-4 pt-2 pb-4 border rounded">
							<div className="w-100 px-4 mb-4 mt-4 d-flex flex-column gap-2 position-relative">
								<label
									htmlFor="video-title"
									className="fs-6 cursor-pointer mb-1"
								>
									Enter Video Title
								</label>
								<input
									className="form-control video-title-input-container"
									type="text"
									name="video-title"
									id="video-title"
									area-label="Enter Video Title"
									placeholder="Enter title for video..."
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								/>
							</div>
							<div className="w-100 px-4 mb-4 mt-4 d-flex flex-column gap-2 position-relative">
								<label
									htmlFor="video-description"
									className="fs-6 cursor-pointer mb-1"
								>
									Enter Video Description
								</label>
								<div style={{ maxWidth: '100%', maxHeight: '200px', overflow: 'hidden' }}>
									<textarea
										rows="6"
										style={{ height: '100px' }}
										className="form-control video-search-input-container"
										type="text"
										area-label="Enter Video Description"
										placeholder="Enter description for video..."
										value={description} 
										onChange={(e)=>setDescription(e.target.value)}
										/>
								</div>
							</div>
							<div className="w-100 px-4 mb-4 mt-4 d-flex flex-column gap-2 position-relative">
								<label
									htmlFor="video-search"
									className="fs-6 cursor-pointer mb-1"
								>
									YouTube URL
								</label>
								<input
									className="form-control video-search-input-container"
									type="text"
									name="video-search"
									id="video-search"
									placeholder="Enter YouTube Video URL..."
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
							</div>

							<div className="btn-container w-100 d-flex justify-content-end gap-2 align-items-center">
								<button
									onClick={handleGoingBack}
									className="btn btn-dark px-3 py-1"
								>
									<IoIosArrowBack />
									{"  "}Back
								</button>
								{searchQuery != '' ? (
									<button
										onClick={() => handleSubmit()}
										className="btn btn-dark px-3 py-1"
									>
										Continue{"  "}
										<IoIosArrowForward />
									</button>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default YoutubePage;

import React from "react";
import "./Dfy.styles.css";

const Dfy = () => {
	return (
		<>
			<div className="right-container">
				<div className="content">
					<div className="container-fluid">
						{/* start page title  */}
						<div className="row mb-2">
							<div className="col-12 ">
								<div className="page-title-box w-100">
									<h4 className="page-title">DFY</h4>
								</div>
							</div>
						</div>
						{/* end page title  */}
						{/* Card  */}
						<div className="row">
							<div className="col-md-12">
								<div className="card w-100">
									<div className="head-part mb-0">
										<h6 className="profile-heading">Done for you</h6>
									</div>
									<div className="card-body">
										<h6 className="description-text">To Activate CloneBuddy AI DFY Edition, Please Contact Our Support Desk With Code <span className='fw-bold'>"7CBUDDYDFYACTIVATION25"</span> With Your CloneBuddy AI Account Details,They will set up your account - </h6>
										<div className=" mt-4 mb-3">
											<a className="btn btn-primary" href="https://imtopvendor.tawk.help/" target="_blank">Support Desk</a>
										</div>

									</div>
								</div>
							</div>
						</div>
						{/* End Card */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Dfy;

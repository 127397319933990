import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import AnimationSpinner from "../../AnimationSpinner";
import emptyIcon from '../../assests/images/emptyIcon.png';
import { AppContext } from "../../context/AppContext";
import { MdDomainAdd } from "react-icons/md";
// bootstrap imports
import { Button, Pagination } from "react-bootstrap";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEllipsisVertical } from "react-icons/fa6";
import { IoIosAddCircleOutline } from "react-icons/io";

const DomainList = ({ base_url }) => {
    const { packageAccess } = useContext(AppContext)
    const [domain, setDomain] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    console.log(packageAccess)
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedDomain, setSelectedDomain] = useState("");

    // models
    const [showNameModal, setShowNameModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // states related to custom domain
    const [domainModel, setShowDomainModel] = useState(false);
    const [isDomain, setIsDomain] = useState(false);
    const [customDomainName, setCustomDomainName] = useState('');


    // constants for range option----------------------------
    const [itemsPerPage, setItemsPerPage] = useState(10);

    // sweetalert function--------------------------------------------------------
    function Sweetalert(field) {
        Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: field + " is required!",
        });
    }


    // model functions start----------------------------------------------
    const closeModel = () => {
        setSelectedDomain(null);
        setShowDomainModel(false);
        setShowNameModal(false);
    };
    const handleDomainClick = async (domainData) => {
        setSelectedDomain(domainData);
        setCustomDomainName(domainData.name)
        setShowDomainModel(true);
    }
    const handleEditNameClick = (id) => {
        const domain_id = domain.find((domain) => domain.id === id);
        if (domain_id) {
            setSelectedDomain(domain_id);
            setShowNameModal(true);
        }
    };
    // model functions end--------------------------------------------------


    // core functons--------------------------------------------------------

    // fetching domain list--------------------------------------------------
    const fetchDomainUsers = async () => {
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            if (searchTerm == '') {
                setLoading(true);
            }
            const formData = new FormData();
            formData.append("action", "getdomainList");
            formData.append("token", token);
            formData.append("offset", currentPage);
            formData.append("limit", itemsPerPage ? itemsPerPage : 10);
            formData.append("search", searchTerm);
            const response = await axios.post(
                `${base_url}api/user/get-domain-details`,
                formData
            );

            if (response.data.status === true) {
                setLoading(false);
                const updatedDomain = response.data.domains.map((domain) => {
                    const createdAt = new Date(domain.created * 1000);
                    domain.created = createdAt.toLocaleString();
                    return domain;
                });
                setDomain(updatedDomain || []);
                setTotalItems(response.data.total_items || 0);
            } else {
				setLoading(false);
				setDomain([]);
                setTotalItems(0);
                if (response.data.reset === true) {
                    handleInvalidToken(navigate);
                    return;
                }
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };

    // handle custom domain update
    const handleCustomDomainAction = async (e) => {
        e.preventDefault();
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }

            if (selectedDomain.customDomain !== '' || null) {
                const trimmedDomain = customDomainName.trim();
                if (trimmedDomain.length > 30) {
                    Swal.fire({
                        icon: "error",
                        title: "Invalid Domain",
                        text: "Custom domain must not be longer than 30 characters.",
                    });
                    return;
                }
                if (trimmedDomain != '' && !trimmedDomain.endsWith('.com')) {
                    Swal.fire({
                        icon: "error",
                        title: "Invalid Domain",
                        text: "Domain must end with '.com'.",
                    });
                    return;
                }
                const cleanDomain = trimmedDomain.replace(/^(https?:\/\/)?(www\.)?/i, '');
                const domainRegex = /^(?!\-)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/;

                if (trimmedDomain != '' && !domainRegex.test(cleanDomain)) {
                    Swal.fire({
                        icon: "error",
                        title: "Invalid Domain",
                        text: "Please add a valid domain without http/https/www.",
                    });
                    return;
                }
            }

            setLoading(true);
            const formData = new FormData();
            formData.append("action", "updateMembershipDomain");
            formData.append("token", token);
            formData.append("domain_id", selectedDomain.id);
            formData.append("custom_domain", customDomainName);
            const response = await axios.post(
                `${base_url}api/user/add-custom-domain`,
                formData
            );

            if (response.data.status === true) {
                setLoading(false);
                setShowDomainModel(false);
                fetchDomainUsers();
                Swal.fire({
                    icon: "success",
                    title: "Custom domain updated",
                    text: "Custom domain updated successfully.",
                });
            } else {
                setLoading(false);
                if (response.data.reset === true) {
                    handleInvalidToken(navigate);
                    return;
                }
                Swal.fire({
                    icon: "error",
                    title: "Failed to update custom domain. Please try again.",
                    text: response.data.message,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };
    // deleting domain function--------------------------------------------------
    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this item!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                const token = GetToken();
                if (!token) {
                    handleInvalidToken(navigate);
                    return;
                }
                setLoading(true);
                const formData = new FormData();
                formData.append("action", "delete_domain");
                formData.append("token", token);
                formData.append("domain_id", id);
                const response = await axios.post(
                    `${base_url}api/user/delete-domain-details`,
                    formData
                );

                if (response.data.status === true) {
                    setLoading(false);
                    setDomain(domain.filter((item) => item.id !== id));
                    Swal.fire("Deleted!", "Your domain has been deleted.", "success");
                } else {
                    setLoading(false);
                    if (response.data.reset === true) {
                        handleInvalidToken(navigate);
                        return;
                    }
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: response.data.message,
                    });
                }
            } catch (error) {
                setLoading(false);
                Swal.fire("Error", "Failed to delete domain", "error");
            }
        }
    };

    // updating domain function--------------------------------------------------
    const handleDomainUpdate = async (e, var_domain) => {
        e.preventDefault();
        setShowNameModal(false);
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            setLoading(true);
            const formData = new FormData();
            formData.append("action", "update_domain");
            formData.append("token", token);
            formData.append("domain_id", var_domain.id);
            formData.append("sub_domain_name", var_domain.sub_domain_name);
            formData.append("description", var_domain.description);

            const response = await axios.post(
                `${base_url}api/user/update-domain-details`,
                formData
            );
            if (response.data.status === true) {
                setLoading(false);
                setSelectedDomain(null);
                fetchDomainUsers();
                Swal.fire({
                    icon: "success",
                    title: "domain updated",
                    text: "Your domain details have been updated successfully.",
                });
            } else {
                setLoading(false);
                if (response.data.reset === true) {
                    handleInvalidToken(navigate);
                    return;
                }
                Swal.fire({
                    icon: "warning",
                    title: "Failed to update domain details. Please try again. ",
                    text: response.data.message,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };

    useEffect(() => {
        fetchDomainUsers();
    }, [
        currentPage,
        navigate,
        base_url,
        searchTerm,
        itemsPerPage,
    ]);

    useEffect(() => {
        setCurrentPage(1)
    }, [itemsPerPage])
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    useEffect(() => {
        if (selectedDomain) {
            setCustomDomainName(selectedDomain.custom_domain)
        }
    }, [selectedDomain])


    const handleUnlockFeature = () => {
        Swal.fire({
            icon: "info", // Change to info to indicate the upgrade information
            title: "Unlock Premium Feature",
            text: "You need the Premium plan to unlock this feature. Please upgrade to access this functionality.",
            confirmButtonText: 'Upgrade Now', // Text for the button
            confirmButtonColor: '#3085d6', // Customize the button color (optional)
            showCancelButton: true, // Add a cancel button
            cancelButtonText: 'Close',
            cancelButtonColor: '#d33',
            reverseButtons: true, // To reverse the buttons (Upgrade Now first, Close second)
            preConfirm: () => {
                window.open("https://getclonebuddyai.com/premium-oto1", "_blank");
            }
        });
    }

    return (
        <div className="container h-100">
            <AnimationSpinner show={loading} />
            <div className="right-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-card">
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="head-part d-flex align-items-center justify-content-between mx-0">
                                            <h3 className="title">Manage Domain</h3>
                                            <div>
                                                <Link to="/create-domain">
                                                    <button type="button" className="btn btn-primary mr-3 mb-2">
                                                        New domain
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div id="dataTable_domain_user_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row align-items-center">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="dataTable_domain_user_length">
                                                        <label className="d-flex align-items-center">
                                                            show
                                                            <select
                                                                id="formRange"
                                                                className="form-select custom-form-select"
                                                                value={itemsPerPage}
                                                                onChange={(e) => setItemsPerPage(e.target.value)}
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select>
                                                            entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div id="dataTable_domain_user_filter" className="dataTables_filter">
                                                        <label className="form-label w-100">
                                                            <input
                                                                type="search"
                                                                className="form-control custom-form-control mt-2"
                                                                placeholder="Search for domain"
                                                                aria-controls="dataTable_domain_user"
                                                                value={searchTerm}
                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {domain.length > 0 ?
                                                (
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="table-responsive">
                                                                <table
                                                                    id="dataTable_domain_user"
                                                                    className="table table-striped w-100 dataTable no-footer"
                                                                    role="grid"
                                                                    aria-describedby="dataTable_domain_user_info"
                                                                    style={{ width: "1067px" }}
                                                                >
                                                                    <thead className="table-head">
                                                                        <tr role="row">
                                                                            <th>Status</th>
                                                                            <th>Created Date</th>
                                                                            <th>Domain name</th>
                                                                            <th>Custom Domain</th>
                                                                            <th>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {domain.map((domain, index) => (
                                                                            <tr role="row" className={index % 2 == 1 ? "even" : "odd"} key={domain.id}>
                                                                                <td>
                                                                                    <span
                                                                                        className={`badge ${domain.status == 1 ? "text-bg-success" : "text-bg-danger"
                                                                                            }`}
                                                                                    >
                                                                                        {domain.status == 1 ? "active" : "inactive"}
                                                                                    </span>
                                                                                </td>
                                                                                <td>{domain.created}</td>
                                                                                <td>{domain.sub_domain_name}</td>
                                                                                <td>
                                                                                    {domain.custom_domain === "" || domain.custom_domain === null ? (
                                                                                        // Check if "premium" is in packageAccess
                                                                                        packageAccess.includes("premium") ? (
                                                                                            <MdDomainAdd onClick={() => handleDomainClick(domain)} title="add-custom-domain" className="add-custom_domain" />
                                                                                        ) : (
                                                                                            <IoIosAddCircleOutline onClick={() => handleUnlockFeature()} title="upgrade this feature" className="add-custom_domain" />

                                                                                        )
                                                                                    ) : domain.custom_domain}
                                                                                </td>

                                                                                <td>
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn btn-light">
                                                                                            <FaEllipsisVertical />
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            {domain.custom_domain === "" || domain.custom_domain === null ? null : (
                                                                                                <Dropdown.Item onClick={() => handleDomainClick(domain)}>
                                                                                                    <FaRegEdit className="f17 text-success me-2 mb-1" />
                                                                                                    Update Custom Domain
                                                                                                </Dropdown.Item>
                                                                                            )}
                                                                                            <Dropdown.Item onClick={() => handleEditNameClick(domain.id)}>
                                                                                                <FaRegEdit className="f17 text-success me-2 mb-1" />
                                                                                                Edit
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => handleDelete(domain.id)}>
                                                                                                <RiDeleteBin6Fill className="delete-icon" />
                                                                                                Delete
                                                                                            </Dropdown.Item >
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) :
                                                (<div className="row mt-4 mb-5">
                                                    <div className="col-lg-5 col-md-8 mx-auto">
                                                        <div className="empty-box">
                                                            <div className="card-body">
                                                                <div className="list-empty-box">
                                                                    <div className="icon">
                                                                        <img src={emptyIcon} alt="No data available" />
                                                                    </div>
                                                                    <p>No data available</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)}
                                            <div className="row justify-content-between align-items-center mt-4 mb-4">
                                                <div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
                                                    <Pagination className="mb-0">
                                                        <Pagination.Prev
                                                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                                            disabled={currentPage === 1}
                                                        />
                                                        {[...Array(totalPages)].slice(Math.floor((currentPage - 1) / 10) * 10, Math.floor((currentPage - 1) / 10) * 10 + 10).map((_, index) => (
                                                            <Pagination.Item
                                                                key={index + 1 + Math.floor((currentPage - 1) / 10) * 10}
                                                                active={index + 1 + Math.floor((currentPage - 1) / 10) * 10 === currentPage}
                                                                onClick={() => setCurrentPage(index + 1 + Math.floor((currentPage - 1) / 10) * 10)}
                                                            >
                                                                {index + 1 + Math.floor((currentPage - 1) / 10) * 10}
                                                            </Pagination.Item>
                                                        ))}
                                                        <Pagination.Next
                                                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                                            disabled={currentPage === totalPages}
                                                        />
                                                    </Pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showNameModal && (
                <div className="modal d-block">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Domain Details</h5>
                                <button type="button" className="close" onClick={closeModel}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={(e) => handleDomainUpdate(e, selectedDomain)}>
                                    <div className="mb-3">
                                        <label className="form-label">Domain Name</label>
                                        <input
                                            type="text"
                                            className="form-control custom-form-control"
                                            value={selectedDomain.sub_domain_name}
                                            onChange={(e) =>
                                                setSelectedDomain((prevdomain) => ({
                                                    ...prevdomain,
                                                    sub_domain_name: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Description</label>
                                        <textarea
                                            className="form-control custom-form-control"
                                            value={selectedDomain.description || ''}
                                            onChange={(e) =>
                                                setSelectedDomain((prevdomain) => ({
                                                    ...prevdomain,
                                                    description: e.target.value,
                                                }))
                                            }
                                            rows="8"
                                        />
                                    </div>

                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            )}
            {domainModel && (
                <div className="modal d-block">
                    <div className="modal-dialog">
                        <div className="modal-content custom-domain-model">
                            <div className="modal-header">
                                <h5 className="modal-title" id="custom_domainLabel">Add existing domain</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-12 mt-4 mx-auto ">
                                                <form method="POST" id="customForm">
                                                    <span id="domainstatus" className="text-success"> </span>
                                                    <div className="form-group">
                                                        <label htmlFor="custom_domain">Custom Domain</label>
                                                        <input
                                                            type="text"
                                                            name="domain_custom"
                                                            placeholder="Enter Custom Domain" className="form-control ml-10 " id="domain_custom"
                                                            value={customDomainName || ""}
                                                            onChange={(e) => { setCustomDomainName(e.target.value) }}
                                                        />
                                                        <p className="form-text text-muted">Enter the domain you want to connect.Please
                                                            add your domain without http/https/www .</p>
                                                        <div className="form-group">
                                                            <label>Configure your DNS records</label> <br />
                                                            <p className="des">
                                                                You'll need to setup a DNS record to point to your <span className="text-dark fw-bold" id="customDomainName">{`${selectedDomain.sub_domain_name ? selectedDomain.sub_domain_name : ""}.clonebuddyai.com`}</span> on our server. DNS records can be setup through your domain registrars control panel. Since every registrar has a different setup, contact them for assistance if you're unsure.
                                                            </p>
                                                            <table className="table table-nowrap card-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Type</th>
                                                                        <th>Record</th>
                                                                        <th>Value</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>CNAME</td>
                                                                        <td id="cnameRecordValue">{selectedDomain.custom_domain || ""}</td>
                                                                        <td id="aiassistant_subdomain">
                                                                            {`${selectedDomain.sub_domain_name ? selectedDomain.sub_domain_name : ""}.clonebuddyai.com`}
                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <p className="form-text text-muted">
                                                                DNS changes may take up to 48-72 hours to take effect, although it's normally a lot faster than that. You will receive a reply when your custom domain has been activated. Please allow 1-2 business days for this process to complete.
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="modal-footer">
                                                        <button className="btn btn-outline-primary mx-1" type="button" data-dismiss="modal" onClick={closeModel}>Cancel</button>
                                                        <button type="submit" form="customForm" id="btnSubmit" className="btn btn-primary my-3" onClick={handleCustomDomainAction}> {selectedDomain.custom_domain === "" || selectedDomain.custom_domain === null ? 'Add' : 'Update'}</button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}

export default DomainList

import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import AnimationSpinner from '../../AnimationSpinner';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";

const AiStockGifs = () => {
  const [gifData, setGifData] = useState([]);
  const [gifQuery, setGifQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGifSearch = async (e) => {
    e?.preventDefault();
    if (!gifQuery.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a keyword to generate GIFs",
      });
      return;
    }
    setLoading(true);
    setGifData([]); // Clear existing GIFs before generating new ones

    try {
      const url = `https://tenor.googleapis.com/v2/search?q=${gifQuery}&key=AIzaSyCAFoNlc0g97-ObIcjonQn-ro4xeuweygs&limit=50`;
      const response = await axios.get(url);

      if (!response.data.results || response.data.results.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No results found! Please try again with different keywords.",
        });
        return;
      }

      setGifData(response.data.results); // Set new GIFs data
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while fetching GIFs. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setGifQuery('');
    setGifData([]);
  };

  const handleDownloadGif = (url) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = objectURL;
        a.download = 'gif';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(objectURL);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "GIF downloaded successfully.",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to download GIF. Please try again.",
        });
      });
  };

  return (
    <div className="right-container">
      <AnimationSpinner show={loading} />
      <div className="container h-75">
        <div className="row">
          <div className="col-sm-12">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card-container">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-end">
                        <div className="col-lg-12 col-md-6 mb-4">
                        <div className="mb-4 border-bottom dashed">
                        <div className="d-flex justify-content-between align-items-center">
                              <h4 className="mb-4">AI Stock Gif's Generator</h4>
                              <Link to={'/ai-stock'}> <Button><IoMdArrowRoundBack /></Button></Link>
                            </div>
                          </div>
                          <label htmlFor="keyword" className="text-md-right mb-2">Enter Keyword Here</label>
                          <input
                            name="keyword"
                            type="search"
                            className="form-control rounded-3"
                            id="keyword"
                            placeholder="Enter keyword..."
                            required
                            value={gifQuery}
                            onChange={(e) => setGifQuery(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex align-items-center justify-content-end mb-2 gap-2" id="reset-button-container">
                          <Button onClick={handleGifSearch} className="btn btn-secondary fw-bol d-flex align-items-center justify-content-center">
                            Generate
                          </Button>
                          <Button onClick={handleReset} className="btn btn-secondary fw-bol d-flex align-items-center justify-content-center">
                            Reset
                          </Button>
                        </div>
                      </div>
                      <div className={`row ${gifData.length > 0 ? "" : "d-none"}`}>
                        <div className="col-lg-12">
                          <div id="show_image">
                            <div className="card-header">
                              <h5 className="card-title">Generated GIFs</h5>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 mx-auto">
                                <div className="mb-4">
                                  <div id="gif" className="row mt-4">
                                    {gifData.length > 0 ? (
                                      gifData.map((gif, index) => (
                                        <div className="col-lg-3 col-md-6" key={index}>
                                          <div className="vc-box p-0">
                                            <div className="vc-media">
                                              <img src={gif.media_formats.mediumgif.url} alt={`GIF ${index + 1}`} />
                                            </div>
                                            <div className="vc-btn">
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => handleDownloadGif(gif.media_formats.mediumgif.url)}
                                              >
                                                Download
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiStockGifs;

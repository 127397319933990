import React, { createContext, useState, useEffect } from 'react';
import GetToken from "../GetToken";
import { fetchMenuPermissions } from "../fetchMenuPermissions";
import axios from "axios";
export const AppContext = createContext();

// provider component
export const AppProvider = ({ children }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isSmallScreen, setIsSmallScreen] = useState(false);
	const [menuPermissionArray, setMenuPermissionArray] = useState([]);
	const [menuFullPermission, setMenuFullPermission] = useState(false);
	const [packageAccess, setPackageAccess] = useState([]);
	const [features, setFeatures] = useState([]);
	const [customDomain, setCustomDomain] = useState('')

	const [selectedMethod, setSelectedMethod] = useState('search');
	const [videoData, setVideoData] = useState({
		type: '',
		videoId: '',
		url: '',
		title: '',
		source: '',
		file: null,
		slug: '',
		description: ''
	});
	const base_url =
		window.location.hostname === "localhost"
			? "https://clonebuddyai.com/"
			: window.location.hostname === "192.168.29.222"
				? "http://192.168.29.222/clonebuddy/"
				: "https://clonebuddyai.com/api/";

	const setPermissions = (menu_permissions, package_access, features_array) => {
		if (menu_permissions === 0) {
			setMenuFullPermission(true);
			setMenuPermissionArray([]);
		} else {
			setMenuFullPermission(false);
			setMenuPermissionArray(menu_permissions);
		}
		setPackageAccess(package_access);
		setFeatures(features_array);
	};

	const clearPermissions = () => {
		setMenuPermissionArray([]);
		setMenuFullPermission(false);
		setPackageAccess([]);
		setFeatures([]);

	};




	const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
	useEffect(() => {
		const token = GetToken();
		if (token) {
			fetchMenuPermissions(token, base_url, setPermissions);
		}
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setIsSidebarOpen(false);
				setIsSmallScreen(true);
			} else {
				setIsSidebarOpen(true);
				setIsSmallScreen(false);
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const generateCustomDomainURL = async () => {
			try {
				const formData = new FormData();
				formData.append('domain', window.location.hostname);
				const response = await axios.post(base_url + 'api/user/generate-custom-domain-url', formData);
				if (response.data.customURL) {
					setCustomDomain(response.data.customURL)
					window.location.href = response.data.customURL;
					console.log(response.data.customURL);
				} else {
					setCustomDomain(null);
					console.log('No custom URL generated.');
				}
			} catch (error) {
				console.error('Error:', error);

			}
		};
		generateCustomDomainURL();
	}, []);

	return (
		<AppContext.Provider value={{
			isSidebarOpen,
			isSmallScreen,
			toggleSidebar,
			setIsSidebarOpen,
			base_url,
			menuFullPermission,
			menuPermissionArray,
			clearPermissions,
			setPermissions,
			packageAccess,
			features,
			selectedMethod,
			setSelectedMethod,
			videoData,
			setVideoData,
			customDomain, 
			setCustomDomain
		}}>
			{children}
		</AppContext.Provider>
	);
};

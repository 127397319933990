export function truncateTextByWords(text, wordLimit = 10) {
    const words = text.split(/\s+/);
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(' ') + '...'
      : text;
  }
  export function truncateTextByCharacters(text, charLimit = 50) {
    return text.length > charLimit
      ? text.slice(0, charLimit) + '...'
      : text;
  }
  
import React, { useState, useEffect } from "react";
import "./Dashboard.styles.css";
import { Button } from "@mui/material";
import { IoHome } from "react-icons/io5";

import AvtarImg from "../../assests/images/avtar.png";
import RoboImg from "../../assests/images/robot.png";
import supportImage from "../../assests/images/ai-support-removebg-preview.png";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate, useParams, Link } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";


// react icons
import { MdOndemandVideo } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import { FaDesktop } from "react-icons/fa6";
import { GiUpgrade } from "react-icons/gi";
import { MdDomain } from "react-icons/md";
import { FaTools } from "react-icons/fa";

const Dashboard = (props) => {
	const navigate = useNavigate();
	const [demoVideo, setDemoVideo] = useState("");
	const [websiteCount, setWebsiteCount] = useState("");
	const [agencyUserCount, setagencyUserCount] = useState("");
	const [videoCount, setVideoCount] = useState("");
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState("username");
	const [userAvatar, setUSerAvatar] = useState();

	useEffect(() => {
		const fetchDashboardData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				// setLoading(true)
				const formData = new FormData();
				formData.append("action", "getDashbordDetails");
				formData.append("token", token);

				const response = await axios.post(
					`${props.base_url}api/user/get-user-resource`,
					formData
				);

				if (response.data.status) {
					setLoading(false);
					setDemoVideo(response.data.video);
					setVideoCount(response.data.video_count); 
					setWebsiteCount(response.data.website_count);
					setagencyUserCount(response.data.agency_user_count);
				} else {
					if (response.data.reset) {
						setLoading(false);
						handleInvalidToken(navigate);
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		};

		const fetchUserData = async () => {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				// setLoading(true)
				const formData = new FormData();
				formData.append("action", "getProfile");
				formData.append("token", token);

				const response = await axios.post(
					`${props.base_url}api/user/get-user-resource`,
					formData
				);

				if (response.data.status) {
					setLoading(false);
					setUsername(response.data.items.name);
					setUSerAvatar(response.data.items.avatar);
				} else {
					if (response.data.reset) {
						setLoading(false);
						handleInvalidToken(navigate);
					} else {
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Error",
					text:
						error.response?.data?.message ||
						error.message ||
						"Something went wrong. Please try again.",
				});
			}
		}
		fetchUserData();
		fetchDashboardData();
	}, [navigate, props.base_url]);
	return (
		<>
			<div className="container">
				<AnimationSpinner show={loading} />
				<div className="right-container">
					<div className="welcome-container mb-4">
						<div className="row">
							{/* quick links start */}
							<div className="col-lg-8 col-md-12 mb-4">
								<div className="quick-links-div">
									<h6 className="main-title">Frequency</h6>
									<div className="row p-2">
										<div className="col-12 col-md-4 col-lg-4">
											<div className="quick-inner-box mt-2 mb-3 link1 p-2">
												<div className="link-name">
													<h6 className="title">Total Website Clone</h6>
													<FaCopy className="icon fs-3 me-2" />
												</div>
												<div className="qlc-rb rb1">
													<div className="qlc-link-container">
														{websiteCount}
													</div>
												</div>
											</div>
										</div>

										<div className="col-12 col-md-4 col-lg-4">
											<div className="quick-inner-box mt-2 mb-3 link2 p-2">
												<div className="link-name">
													<h6 className="title">Total Video Clone <MdOndemandVideo /> </h6>
													<MdOndemandVideo className="icon fs-3 me-2" />
												</div>
												<div className="qlc-rb rb2">
													<div className="qlc-link-container">
													{videoCount}
													</div>
												</div>
											</div>
										</div>

										<div className="col-12 col-md-4 col-lg-4">
											<div className="quick-inner-box mt-2 mb-3 link3 p-2">
												<div className="link-name">
													<h6 className="title">Total Agency Users</h6>
													<FaTools className="icon fs-3 me-2" />
												</div>
												<div className="qlc-rb rb3">
													<div className="qlc-link-container">
														{agencyUserCount}
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
							{/* quick links end */}

							{/* assist conetent start*/}
							{/* <div className="col-md-12 col-lg-4 z-0">
								<div className="assist-container">
									<div className="assist-content">
										<h3>How we can Assist You?</h3>
										<p>
											Explore our comprehensive articles and FAQs for quick
											answers to common questions.
										</p>
										<div className="d-flex justify-content-start align-items-center assist-button-container">
											<Link to="https://imtopvendor.tawk.help/" target="_blank" className="me-3 ">
											<Button>Support</Button>
										</Link>
											<Link to="https://imtopvendor.tawk.help/" target="_blank">
												<Button className="btn-primary">Support</Button>
											</Link>
										</div>
									</div>
									<div className="support-image">
										<img src={supportImage} alt="Support-img" />
									</div>
								</div>
							</div> */}
							{/* assist conetent end */}
							<div className="col-lg-4 col-md-12">
								<div className="welcome-content">
									<div className="user-content">
										<div className="user-image-container">
											<img src={userAvatar ? userAvatar : AvtarImg} alt="fffff" />
										</div>
										<h3>{username}</h3>

									</div>
									<div className="welcome-text-container">
										<h2>Welcome to CloneBuddy AI </h2>
										<p>
											CloneBuddy AI empowers users to replicate any website's entire structure, including its themes, templates, content, images, videos, databases, and pages, with ease and precision.
										</p>

										<Link to="/profile">
											<Button className="btn-primary">View Profile</Button>
										</Link>
									</div>
								</div>
							</div>

						</div>
					</div>



					<div className="row  flex-column-reverse flex-md-column-reverse flex-lg-row">
						{/* demo video start */}
						<div className="col-lg-12 col-md-12">
							<div className="introduction-content">
								<h4>Preview Video</h4>
								<div className="embed-responsive embed-responsive-16by9 h-100">
									{/* <img
										src="https://cdn.convertri.com/0d3f9636-68a8-11ed-aeb6-06deec350f13%2F1bcd3c3becec3ff0e906d840e607dcbc5d23f4d6%2F0d3f9636-68a8-11ed-aeb6-06deec350f13_698bc0e7def8fd8147075bdf0c12f0add79fafbf_Bundle.png"
										style={{ width: "100%", height: "auto" }}
										alt="Preview"
									/> */}
									<iframe
										width="100%"
										height="600"
										src={demoVideo}
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
									></iframe>
								</div>
							</div>
						</div>
						{/* demo video end */}


					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import "./Sidebar.styles.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";


// images
import sidebarImg1 from "../../assests/images/sidebar/sidebar1.png";
import sidebarImg2 from "../../assests/images/sidebar/sidebar2.jpg";

// react icons
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { FaTools } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GiUpgrade } from "react-icons/gi";
import { SiAudiobookshelf } from "react-icons/si";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaGifts } from "react-icons/fa";
import { FaStore } from "react-icons/fa";
import { FaGift } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import { MdManageHistory } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa6";
import { FaWandSparkles } from "react-icons/fa6";
import { FaDesktop } from "react-icons/fa6";
import { FaBookOpen } from "react-icons/fa6";
import { RiVideoAddFill } from "react-icons/ri";
import { MdOutlineDomainAdd } from "react-icons/md";
import { GrDomain } from "react-icons/gr";
import { FaShapes } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { FaPenFancy } from "react-icons/fa";
import { GrChatOption } from "react-icons/gr";
import { FaArchive } from "react-icons/fa";

const Sidebar = () => {
	const {
		menuPermissionArray,
		menuFullPermission,
		isSidebarOpen,
		setIsSidebarOpen,
		isSmallScreen,
		clearPermissions,
		packageAccess,
		features,
		base_url
	} = useContext(AppContext);

	const [activeTab, setActiveTab] = useState(null);
	const [ebookURL, setEbookURL] = useState("");
	const [contentSuiteURL, setContentSuiteURL] = useState("");
	const [audiobookURL, setAudiobookURL] = useState("");
	const [storeURL, setStoreURL] = useState("");

	const navigate = useNavigate();

	const handleLogout = () => {
		Swal.fire({
			title: "Are you sure you want to log out?",
			icon: "warning",
			showDenyButton: true,
			confirmButtonText: "Yes, log me out",
			denyButtonText: "No, stay logged in",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"Logged out",
					"You have been successfully logged out.",
					"success"
				);
				clearPermissions();
				handleInvalidToken(navigate);
			} else if (result.isDenied) {
				Swal.fire("Stayed logged in", "You chose to stay logged in.", "info");
			}
		});
	};
	useEffect(() => {
		var email = localStorage.getItem("email");
		setEbookURL(
			"https://aiebookpal.com/enter/clone-buddy-ebook?user=" + btoa(email)
		);
		setAudiobookURL(
			"https://aiebookpal.com/enter/clone-buddy-audiobook?user=" + btoa(email)
		);
		setStoreURL(
			"https://storebuddyai.com/app/clone-buddy-e-com-store?user=" + btoa(email)
		);
	}, []);

	const handleFeatureAccess = (feature) => {
		return feature ? packageAccess.includes(feature) : true;
	};

	const handleAccessDenied = (feature) => {
		const featureInfo = features.find((feat) => feat.value === feature);
		const upgradeUrl = featureInfo?.upgrade_url;

		Swal.fire({
			title: `Unlock ${featureInfo?.name || "Feature"}!`,
			text: `${featureInfo?.upgrade_title ||
				"Upgrade your plan to access this feature."
				}`,
			icon: "info",
			showCancelButton: false,
			confirmButtonText: "Upgrade Now",
		}).then((result) => {
			if (result.isConfirmed) {
				window.open(upgradeUrl, "_blank");
			}
		});
	};

	const handleMenuClick = (feature, url, hasSubmenu, index, targetBlank) => {
		if (handleFeatureAccess(feature)) {
			if (url) {
				if (targetBlank) {
					window.open(url, "_blank");
				} else {
					navigate(url);
				}
			}
			handleItemClick(hasSubmenu, index);
		} else {
			handleAccessDenied(feature);
		}
	};

	const handleSubmenuClick = (feature, url, index) => {
		if (handleFeatureAccess(feature)) {
			navigate(url);
			handleSubmenuItemClick();
		} else {
			handleAccessDenied(feature);
		}
	};

	const handleItemClick = (hasSubmenu, index) => {
		if (isSidebarOpen) {
			if (isSmallScreen && !hasSubmenu) {
				setIsSidebarOpen(false);
			}
			setActiveTab((prev) => (prev === index ? null : index));
		} else {
			setIsSidebarOpen(true);
			setActiveTab(index);
		}
	};

	const handleSubmenuItemClick = () => {
		if (isSmallScreen) {
			setIsSidebarOpen(false);
		}
	};

	useEffect(() => {
		if (handleFeatureAccess("premium")) {
			const fetchOneTimeLoginUrl = async () => {
				try {
					const token = GetToken();
					if (!token) {
						handleInvalidToken(navigate);
						return;
					}
					const formData = new FormData();
					formData.append("token", token);
					formData.append("action", 'site-clone-action');

					const response = await axios.post(base_url + 'api/user/one-time-code', formData);
					if (response.data.url) {
						setContentSuiteURL(response.data.url); // Set the URL if found
					} else {
						// If URL not found, redirect to the /dashboard route
						navigate("/dashboard");  // Use `navigate` to programmatically redirect to the dashboard
					}
				} catch (error) {
					console.error('Error:', error);
					navigate("/dashboard");
				}
			};
			fetchOneTimeLoginUrl();
		}
	}, []);


	return (
		<div
			className={`sidebar transition ${isSidebarOpen ? "toogleOpen" : "toggleClose"
				}`}
		>
			<ul className="title mb-0 pe-0 mb-4">
				{[
					{
						icon: <RiDashboardHorizontalFill />,
						label: "Dashboard",
						labelUrl: "/dashboard",
						slug: "dashboard",
					},
					{
						icon: <FaCopy />,
						label: "AI Site Clone",
						submenu: [
							{ title: "Clone Site", url: "/clone-site", slug: "site-clone" },
							{
								title: "Manage Site",
								url: "/manage-site",
								slug: "site-manage",
							},
						],
					},
					{
						icon: <MdOutlineDomainAdd />,
						label: "Domain Settings",
						submenu: [
							{
								title: "Create Domain",
								url: "/create-domain",
								slug: "create-domain",
							},
							{
								title: "Manage Domain",
								url: "/manage-domain",
								slug: "manage-domain",
							},
						],
					},
					{
						icon: <RiVideoAddFill />,
						label: "AI Video Clone",
						submenu: [
							{
								title: "Clone Video",
								url: "/ai-video-clone",
								slug: "site-clone",
								feature: "video_clone",
							},
							{
								title: "Manage Video",
								url: "/edited-videos",
								slug: "site-manage",
								feature: "video_clone",
							},
						],
					},
					{
						icon: <FaShapes />,
						label: "AI Website Builder",
						labelUrl: "/website-builder",
						slug: "site-manage",
					},

					{
						icon: <FaUserEdit />,
						label: "AI Content Writer",
						labelUrl: contentSuiteURL,  // This should be dynamically set
						slug: "ai-content-writer",
						feature: "premium",
						targetBlank: "_blank",  // Add this if you want it to open in a new tab
					},
					{
						icon: <FaPenFancy />,
						label: "AI Copy Writer",
						labelUrl: "/ai-copy-writer",
						slug: "ai-copy-writer",
						feature: "premium",
					},
					{
						icon: <GrChatOption />,
						label: "AI Chatbot",
						labelUrl: "/ai-chatbot",
						slug: "site-manage",
						feature: "premium",
					},
					{
						icon: <FaArchive />,
						label: "AI Stock",
						labelUrl: "/ai-stock",
						slug: "ai-stock",
					},

					{
						icon: <FaBookOpen />,
						label: "Ebook Edition",
						labelUrl: ebookURL,
						slug: "ebook",
						feature: "ebook",
						targetBlank: "_blank",
					},
					{
						icon: <SiAudiobookshelf />,
						label: "AudioBook Edition",
						labelUrl: audiobookURL,
						slug: "audiobook",
						feature: "audiobook",
						targetBlank: "_blank",
					},
					{
						icon: <FaStore />,
						label: "Ecom Store Edition",
						labelUrl: storeURL,
						slug: "e-com-store",
						feature: "e_com_store",
						targetBlank: "_blank",
					},

					{
						icon: <FaTools />,
						label: "Agency Edition",
						submenu: [
							{
								title: "Create",
								url: "/create-agency",
								slug: "create_agency",
								feature: "agency",
							},
							{
								title: "Manage",
								url: "/manage-agency",
								slug: "manage_agency",
								feature: "agency",
							},
						],
					},
					{
						icon: <FaGifts />,
						label: "DFY Edition",
						labelUrl: "/dfy",
						slug: "dfy",
						feature: "dfy",
					},
					{
						icon: <FaHandHoldingUsd />,
						label: "Reseller Edition",
						labelUrl: "/reseller",
						slug: "reseller",
						feature: "reseller",
					},
					{
						icon: <FaWandSparkles />,
						label: "Whitelabel Edition",
						labelUrl: "/whitelabel",
						slug: "whitelabel",
						feature: "whitelabel",
					},

					{
						icon: <IoMdSettings />,
						label: "Open AI Settings",
						labelUrl: "/open-ai",
						slug: "open_ai",
					},
					{
						icon: <FaDesktop />,
						label: "Training Videos",
						labelUrl: "/training-videos",
						slug: "training_videos",
					},
					{
						icon: <MdOutlineSupportAgent />,
						label: "Support Desk",
						targetBlank: "_blank",
						labelUrl: "https://imtopvendor.tawk.help/",
						slug: "support",
					},
					{
						icon: <FaGift />,
						label: "Bonuses",
						labelUrl: "https://getclonebuddyai.com/bonuses",
						slug: "bonuses",
						targetBlank: "_blank",
					},

					{
						icon: <GiUpgrade />,
						label: "Upgrade Page",
						labelUrl: "/upgrade",
						slug: "upgrade",
					},
				].map(
					(item, index) =>
						(menuFullPermission ||
							["dashboard", "support", "training_videos"].includes(item.slug) ||
							menuPermissionArray.includes(item.slug) ||
							(item.submenu &&
								item.submenu.some((subitem) =>
									menuPermissionArray.includes(subitem.slug)
								))) && (
							<li key={index}>
								<Button
									className={`w-100 text-start justify-content-start align-items-center text-capitalize ${activeTab === index ? "active" : ""
										}`}
									onClick={() =>
										handleMenuClick(
											item.feature,
											item.labelUrl,
											!!item.submenu,
											index,
											item.targetBlank
										)
									}
								>
									<span className="icon d-flex align-items-center justify-content-center">
										{item.icon}
									</span>
									{isSidebarOpen ? (
										<>
											{item.label}
											{item.submenu && (
												<span className="arrow d-flex align-items-center justify-content-center">
													<FaAngleRight />
												</span>
											)}
										</>
									) : null}
								</Button>
								{item.submenu && isSidebarOpen ? (
									<div
										className={`subMenu-wrapper transition ${activeTab === index ? "open" : "close"
											}`}
									>
										<div className="subMenu transition">
											<ul className="p-0">
												{item.submenu.map(
													(subitem, subIndex) =>
														(menuFullPermission ||
															menuPermissionArray.includes(subitem.slug)) && (
															<li key={subIndex} className="cursor-pointer">
																<a
																	// href="javascript:void(0)"
																	onClick={() =>
																		handleSubmenuClick(
																			subitem.feature,
																			subitem.url,
																			subIndex
																		)
																	}
																>
																	{subitem.title}
																</a>
															</li>
														)
												)}
											</ul>
										</div>
									</div>
								) : null}
							</li>
						)
				)}
			</ul>

			<div className="logout-wrapper">
				<div className="logout-box d-flex align-items-center justify-content-center">
					<Button
						variant="contained"
						onClick={handleLogout}
						className="btn btn-primary"
					>
						<FiLogOut /> Logout
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;


import react, { useEffect, useState, useContext } from 'react';
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { AppContext } from '../../../context/AppContext';

const EditElement = ({ element, onCancel, onAddElement, handleEditProperties, isAdding }) => {
    console.log("element", element);
    // states for handling text element
    const { videoData } = useContext(AppContext);
    const [texData, setTextData] = useState({
        content: "",
        fontSize: "",
        color: "#fff",
        backgroundColor: "#000",
        startTime: "00:00:05",
        duration: "10",
    })

    // states for handling image element
    const [imageData, setImageData] = useState({
        imageAlt: "Dummy Image",
        imagePreview: null,
        borderRadius: "",
        startTime: "00:00:05",
        duration: "10",
        image: null,
    })

    // states for handling button element
    const [buttonData, setButtonData] = useState({
        content: "",
        fontSize: 16,
        color: "#fff",
        backgroundColor: "#000",
        link: "",
        startTime: "00:00:05",
        duration: "10",
    })

    // states for handling stockImage
    const [stockImageData, setStockImageData] = useState({
        imageAlt: '',
        previewURL: null,
        startTime: "00:00:05",
        duration: "10",
        borderRadius: "",
    })
    // states for text watermark
    const [textWatermark, setTextWatermark] = useState({
        content: "",
        fontSize: "",
        color: "#fff",
        startTime: "00:00:05",
        duration: "10",
    })

    // states for handling image watermark element
    const [imageWatermark, setImageWatermark] = useState({
        imageAlt: "Dummy Image",
        imagePreview: null,
        borderRadius: "",
        startTime: "00:00:05",
        duration: "10",
        image: null,
    })
    // ====================================================Handle changes for text editing==============================================================================
    const handleTextElementChange = (keyName, e) => {
        if (keyName == 'duration') {
            setTextData((prevData) => (
                {
                    ...prevData, [keyName]: Number(e.target.value)
                }
            ))
        } else {
            setTextData((prevData) => (
                {
                    ...prevData, [keyName]: e.target.value
                }
            ))
        }

    }
    // ====================================================Handle changes for text_watermark editing==============================================================================
    const handleTextWatermarkChange = (keyName, e) => {
        if (keyName == 'duration') {
            setTextWatermark((prevData) => (
                {
                    ...prevData, [keyName]: Number(e.target.value)
                }
            ))
        } else {
            setTextWatermark((prevData) => (
                {
                    ...prevData, [keyName]: e.target.value
                }
            ))
        }

    }
    // ====================================================Handle changes for image editing================================================================================
    const handleImageElementChange = (keyName, e) => {
        if (keyName === 'imageSrc') {
            const file = e.target.files[0];
            if (file) {
                const previewUrl = URL.createObjectURL(file);
                setImageData((prevData) => ({
                    ...prevData,
                    imageSrc: file,
                    imagePreview: previewUrl,
                    image: file
                }));
            }
        } else if (keyName == 'duration') {
            setImageData((prevData) => (
                {
                    ...prevData, [keyName]: Number(e.target.value)
                }
            ))
        }
        else {
            setImageData((prevData) => (
                {
                    ...prevData, [keyName]: e.target.value
                }
            ))
        }

    }
    const handleStockImageDataChange = (keyName, e) => {
        if (keyName == 'duration') {
            setStockImageData((prevData) => (
                {
                    ...prevData, [keyName]: Number(e.target.value)
                }
            ))
        }
        else {
            setStockImageData((prevData) => (
                {
                    ...prevData, [keyName]: e.target.value
                }
            ))
        }

    }
    // ====================================================Handle changes for image editing================================================================================
    const handleImageWatermarkChange = (keyName, e) => {
        if (keyName === 'imageSrc') {
            const file = e.target.files[0];
            if (file) {
                const previewUrl = URL.createObjectURL(file);
                setImageWatermark((prevData) => ({
                    ...prevData,
                    imageSrc: file,
                    imagePreview: previewUrl,
                    image: file
                }));
            }
        } else if (keyName == 'duration') {
            setImageWatermark((prevData) => (
                {
                    ...prevData, [keyName]: Number(e.target.value)
                }
            ))
        }
        else {
            setImageWatermark((prevData) => (
                {
                    ...prevData, [keyName]: e.target.value
                }
            ))
        }

    }
    // =====================================================Handle changes for Button editing========================================================================
    const handleButtonElementChange = (keyName, e) => {
        if (keyName == 'duration') {
            setButtonData((prevData) => (
                {
                    ...prevData, [keyName]: Number(e.target.value)
                }
            ))
        } else {
            setButtonData((prevData) => (
                {
                    ...prevData, [keyName]: e.target.value
                }
            ))
        }
    }


    const formatTimeFromSeconds = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return [hours, minutes, seconds]
            .map(unit => String(unit).padStart(2, '0'))
            .join(':');
    };
    useEffect(() => {
        if (element?.type === 'text') {
            setTextData(() => ({
                content: element?.content ?? "",
                fontSize: element?.fontSize ?? "",
                color: element?.color ?? "#fff",
                backgroundColor: element?.backgroundColor ?? "#000",
                startTime: element.startTime ? formatTimeFromSeconds(element.startTime) : "00:00:05",
                duration: element?.duration ?? "10",
            }));
        } else if (element?.type === "image") {
            setImageData(() => ({
                imageSrc: element?.imageSrc?.name ?? null, // Check if imageSrc and its name exist
                imageAlt: "Dummy Image",
                imagePreview: element?.imagePreview ?? null,
                borderRadius: element?.borderRadius ?? "",
                startTime: element.startTime ? formatTimeFromSeconds(element.startTime) : "00:00:05",
                duration: element?.duration ?? "10",
            }));
        } else if (element?.type === "button") {
            setButtonData(() => ({
                content: element?.content ?? "",
                fontSize: element?.fontSize ?? "16",
                color: "#fff",
                backgroundColor: "#000",
                link: element?.link?? "",
                startTime: element.startTime ? formatTimeFromSeconds(element.startTime) : "00:00:05",
                duration: element?.duration ?? "10",
            }));
        } else if (element?.type === "stock_image") {
            setStockImageData({
                previewURL: element?.previewURL ?? null,
                startTime: element.startTime ? formatTimeFromSeconds(element.startTime) : "00:00:05",
                duration: element?.duration ?? "10",
                borderRadius: element?.borderRadius ?? "",
                imageAlt: element?.tags ?? 'Stock Image'
            })
        } else if (element?.type === "text_watermark") {
            setTextWatermark({
                content: element?.content ?? "",
                fontSize: element?.fontSize ?? "",
                color: element?.color ?? "#fff",
                startTime: element.startTime ? formatTimeFromSeconds(element.startTime) : "00:00:05",
                duration: element?.duration ?? "10",
            })
        } else if (element?.type === "image_watermark") {
            setImageWatermark(() => ({
                imageSrc: element?.imageSrc?.name ?? null, // Check if imageSrc and its name exist
                imageAlt: element?.imageAlt ?? "watermark image", // Check if imageAlt
                imagePreview: element?.imagePreview ?? null,
                borderRadius: element?.borderRadius ?? "",
                startTime: element.startTime ? formatTimeFromSeconds(element.startTime) : "00:00:05",
                duration: element?.duration ?? "10",
            }));
        }

    }, [element]);
    return (
        <div className="editing-options">
            <div className="d-flex justify-content-between px-2 gap-3 cursor-pointer">
                <h5>{isAdding ? "Adding" : "Editing"}{" "}{element.type}</h5>
                <div onClick={onCancel}>
                    <IoArrowBackCircleSharp className="edit-element-icon" />
                </div>
            </div>
            {/* Conditional rendering based on element type */}
            {element.type === 'text' && (
                <div>
                    <div className="form-group mb-2 px-2 mt-2">
                        <label>Text Content</label>
                        <input
                            type="text"
                            className="form-control"
                            value={texData.content}
                            onChange={(e) => handleTextElementChange("content", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>Font Size in px</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder='Enter font size in px'
                            value={texData.fontSize}
                            onChange={(e) => handleTextElementChange("fontSize", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>Color</label>
                        <input
                            type="color"
                            className="form-control"
                            value={texData.color}
                            onChange={(e) => handleTextElementChange("color", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>BackGround Color</label>
                        <input
                            type="color"
                            placeholder='BackGround Color'
                            className="form-control"
                            value={texData.backgroundColor}
                            onChange={(e) => handleTextElementChange("backgroundColor", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="startTime">Select Start Time</label>
                        <input
                            type="text"
                            className="form-control"
                            name="hour"
                            id="startTime"
                            placeholder='Start Time - HH:MM:SS'
                            maxLength="8"
                            value={texData.startTime}
                            onChange={(e) => handleTextElementChange("startTime", e)}
                        />

                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="timeDuration">Select Duration In Seconds</label>
                        <input
                            type="number"
                            className="form-control"
                            name="hour"
                            id="timeDuration"
                            placeholder='Duration in seconds'
                            value={texData.duration}
                            onChange={(e) => handleTextElementChange("duration", e)}
                        />
                    </div>
                    {isAdding ?
                        (<button className='mt-3 btn btn-dark' onClick={() => onAddElement("text", texData, element)}>Add Element</button>) :
                        (<button className='mt-3 btn btn-dark' onClick={() => handleEditProperties(element.id, texData)}>Save Element</button>)
                    }
                </div>
            )}
            {element.type === 'text_watermark' && (
                <div>
                    <div className="form-group mb-2 px-2 mt-2">
                        <label>Text Content</label>
                        <input
                            type="text"
                            className="form-control"
                            value={textWatermark.content}
                            onChange={(e) => handleTextWatermarkChange("content", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>Font Size in px</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder='Enter font size in px'
                            value={textWatermark.fontSize}
                            onChange={(e) => handleTextWatermarkChange("fontSize", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>Color</label>
                        <input
                            type="color"
                            className="form-control"
                            value={textWatermark.color}
                            onChange={(e) => handleTextWatermarkChange("color", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="startTime">Select Start Time</label>
                        <input
                            type="text"
                            className="form-control"
                            name="hour"
                            id="startTime"
                            placeholder='Start Time - HH:MM:SS'
                            maxLength="8"
                            value={textWatermark.startTime}
                            onChange={(e) => handleTextWatermarkChange("startTime", e)}
                        />

                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="timeDuration">Select Duration In Seconds</label>
                        <input
                            type="number"
                            className="form-control"
                            name="hour"
                            id="timeDuration"
                            placeholder='Duration in seconds'
                            value={textWatermark.duration}
                            onChange={(e) => handleTextWatermarkChange("duration", e)}
                        />
                    </div>
                    {isAdding ?
                        (<button className='mt-3 btn btn-dark' onClick={() => onAddElement("text_watermark", textWatermark, element)}>Add Element</button>) :
                        (<button className='mt-3 btn btn-dark' onClick={() => handleEditProperties(element.id, textWatermark)}>Save Element</button>)
                    }
                </div>
            )}
            {element.type === 'image' && (
                <div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="imageAlt">Enter Image Name</label>
                        <input className="form-control"
                            type="text" id='imageAlt'
                            name='imageAlt'
                            placeholder='Enter image name...'
                            onChange={(e) => handleImageElementChange("imageAlt", e)}
                        />
                        <label htmlFor='imageSrc'>Choose image</label>
                        <input
                            type="file"
                            className="form-control"
                            id='imageSrc'
                            onChange={(e) => handleImageElementChange("imageSrc", e)}
                        />
                        <label htmlFor="imageAlt">Border Radius in px</label>
                        <input
                            className="form-control"
                            type="number" id='imageAlt'
                            name='imageAlt'
                            placeholder='Enter value in px...'
                            value={imageData.borderRadius}
                            onChange={(e) => handleImageElementChange("borderRadius", e)} />

                    </div>
                    <div className='edit-image-container mt-4 ms-4 mb-2'>
                        <img src={imageData.imagePreview || imageData.imageSrc} alt={imageData.imageAlt} />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="startTime">Select Start Time</label>
                        <input
                            type="text"
                            className="form-control"
                            name="hour"
                            id="startTime"
                            value={imageData.startTime}
                            placeholder='Start Time - HH:MM:SS'
                            maxLength="8"
                            onChange={(e) => handleImageElementChange("startTime", e)}
                        />

                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="timeDuration">Select Duration In Seconds</label>
                        <input
                            type="number"
                            className="form-control"
                            name="hour"
                            id="timeDuration"
                            value={imageData.duration}
                            placeholder='Duration in seconds'
                            onChange={(e) => handleImageElementChange("duration", e)}
                        />
                    </div>
                    {isAdding ?
                        (<button className='mt-3 btn btn-dark' onClick={() => onAddElement("image", imageData, element)}>Add Element</button>) :
                        (<button className='mt-3 btn btn-dark' onClick={() => handleEditProperties(element.id, imageData)}>Save Element</button>)
                    }
                </div>
            )}
            {element.type === 'image_watermark' && (
                <div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="imageAlt">Enter Image Name</label>
                        <input className="form-control"
                            type="text" id='imageAlt'
                            name='imageAlt'
                            value={imageWatermark.imageAlt}
                            placeholder='Enter image name...'
                            onChange={(e) => handleImageWatermarkChange("imageAlt", e)}
                        />
                        <label htmlFor='imageSrc'>Choose image</label>
                        <input
                            type="file"
                            className="form-control"
                            id='imageSrc'
                            onChange={(e) => handleImageWatermarkChange("imageSrc", e)}
                        />
                        <label htmlFor="imageAlt">Border Radius in px</label>
                        <input
                            className="form-control"
                            type="number" id='imageAlt'
                            name='imageAlt'
                            placeholder='Enter value in px...'
                            value={imageWatermark.borderRadius}
                            onChange={(e) => handleImageWatermarkChange("borderRadius", e)} />

                    </div>
                    <div className='edit-image-container mt-4 ms-4 mb-2'>
                        <img src={imageWatermark.imagePreview || imageWatermark.imageSrc} alt={imageWatermark.imageAlt} />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="startTime">Select Start Time</label>
                        <input
                            type="text"
                            className="form-control"
                            name="hour"
                            id="startTime"
                            value={imageWatermark.startTime}
                            placeholder='Start Time - HH:MM:SS'
                            maxLength="8"
                            onChange={(e) => handleImageWatermarkChange("startTime", e)}
                        />

                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="timeDuration">Select Duration In Seconds</label>
                        <input
                            type="number"
                            className="form-control"
                            name="hour"
                            id="timeDuration"
                            value={imageWatermark.duration}
                            placeholder='Duration in seconds'
                            onChange={(e) => handleImageWatermarkChange("duration", e)}
                        />
                    </div>
                    {isAdding ?
                        (<button className='mt-3 btn btn-dark' onClick={() => onAddElement("image_watermark", imageWatermark, element)}>Add Element</button>) :
                        (<button className='mt-3 btn btn-dark' onClick={() => handleEditProperties(element.id, imageWatermark)}>Save Element</button>)
                    }
                </div>
            )}
            {element.type === 'button' && (
                <div>
                    <div className="form-group mb-2 px-2">
                        <label>Button Text</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Enter button text'
                            value={buttonData.content}
                            onChange={(e) => handleButtonElementChange("content", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>Font Size in px</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder='Enter font size in px'
                            value={buttonData.fontSize}
                            onChange={(e) => handleButtonElementChange("fontSize", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>Button Link</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='https://www.example.com'
                            value={buttonData.link}
                            onChange={(e) => handleButtonElementChange("link", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>Color</label>
                        <input
                            type="color"
                            className="form-control"
                            value={buttonData.color}
                            onChange={(e) => handleButtonElementChange("color", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label>BackGround Color</label>
                        <input
                            type="color"
                            placeholder='BackGround Color'
                            className="form-control"
                            value={buttonData.backgroundColor}
                            onChange={(e) => handleButtonElementChange("backgroundColor", e)}
                        />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="startTime">Select Start Time</label>
                        <input
                            type="text"
                            className="form-control"
                            name="hour"
                            id="startTime"
                            placeholder='Start Time - HH:MM:SS'
                            maxLength="8"
                            value={buttonData.startTime}
                            onChange={(e) => handleButtonElementChange("startTime", e)}

                        />

                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="timeDuration">Select Duration In Seconds</label>
                        <input
                            type="number"
                            className="form-control"
                            name="hour" id="timeDuration"
                            placeholder='Duration in seconds'
                            value={buttonData.duration}
                            onChange={(e) => handleButtonElementChange("duration", e)}
                        />
                    </div>
                    {isAdding ?
                        (<button className='mt-3 btn btn-dark' onClick={() => onAddElement("button", buttonData, element)}>Add Element</button>) :
                        (<button className='mt-3 btn btn-dark' onClick={() => handleEditProperties(element.id, buttonData)}>Save Element</button>)
                    }

                </div>
            )}
            {element.type == 'stock_image' && (
                <div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="imageAlt">Border Radius in px</label>
                        <input
                            className="form-control"
                            type="number" id='imageAlt'
                            name='imageAlt'
                            placeholder='Enter value in px...'
                            value={stockImageData.borderRadius}
                            onChange={(e) => handleStockImageDataChange("borderRadius", e)} />

                    </div>
                    <div className='edit-image-container mt-4 ms-4 mb-2'>
                        <img src={stockImageData.previewURL} />
                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="startTime">Select Start Time</label>
                        <input
                            type="text"
                            className="form-control"
                            name="hour"
                            id="startTime"
                            value={stockImageData.startTime}
                            placeholder='Start Time - HH:MM:SS'
                            maxLength="8"
                            onChange={(e) => handleStockImageDataChange("startTime", e)}
                        />

                    </div>
                    <div className="form-group mb-2 px-2">
                        <label htmlFor="timeDuration">Select Duration In Seconds</label>
                        <input
                            type="number"
                            className="form-control"
                            name="hour"
                            id="timeDuration"
                            value={imageData.duration}
                            placeholder='Duration in seconds'
                            onChange={(e) => handleStockImageDataChange("duration", e)}
                        />
                    </div>
                    {isAdding ?
                        (<button className='mt-3 btn btn-dark' onClick={() => onAddElement("stock_image", stockImageData, element)}>Add Element</button>) :
                        (<button className='mt-3 btn btn-dark' onClick={() => handleEditProperties(element.id, stockImageData)}>Save Element</button>)
                    }
                </div>
            )}
            {/* {element.type === 'countdown' && (
                <div>
                    <div className="form-group mb-2 px-2">
                        <label>Countdown Time (in seconds)</label>
                        <input
                            type="number"
                            className="form-control"
                        // Handle countdown time
                        />
                    </div>

                    <div className="form-group mb-2 px-2">
                        <label htmlFor="startTime">Select Start Time</label>
                        <input
                            type="text"
                            className="form-control"
                            name="hour"
                            id="startTime"
                            placeholder='Start Time - HH:MM:SS'
                            maxLength="8"
                        />
                    </div>

                    <div className="form-group mb-2 px-2">
                        <label htmlFor="timeDuration">Select Duration In Seconds</label>
                        <input
                            type="number"
                            className="form-control"
                            name="hour"
                            id="timeDuration"
                            placeholder='Duration in seconds'
                        />
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default EditElement
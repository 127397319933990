import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import AnimationSpinner from '../../AnimationSpinner'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";


const AiStockImages = () => {
    const [showStockModel, setShowStockModel] = useState(false);
    const [stockImagedData, setStockImagedData] = useState([]);
    const [stockImageSelected, setStockImageSelected] = useState(false)
    const [stockImage, setStockImage] = useState({})
    const [stockQuery, setStockQuery] = useState("");
    const [orientation, setOrientation] = useState("all");
    const [imageType, setImageType] = useState("all");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const handleStockImageSearch = async (e, page = 1) => {
        e?.preventDefault();
        if (!stockQuery.trim()) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter a keyword to generate images",
            });
            return;
        }

        setLoading(true);
        if (page === 1) setStockImagedData([]);

        try {
            const response = await fetch(
                `https://pixabay.com/api/?key=43529301-48bb67a4a3176c73ad9bd51f3&q=${stockQuery}&image_type=${imageType}&orientation=${orientation}&page=${page}&per_page=12`
            );
            const data = await response.json();

            if (data.total === 0) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Result not found! Please try again with different keywords.",
                });
                return;
            }

            setStockImagedData((prev) => [...prev, ...data.hits]);
            setCurrentPage(page);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "An error occurred while fetching images. Please try again.",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        handleStockImageSearch(null, currentPage + 1);
    };

    const handleReset = () => {
        setStockQuery("");
        setStockImagedData([]);
        setCurrentPage(1);
    };

    const handleSelectStockImage = async (imageId) => {
        setShowStockModel(false);
        const image = stockImagedData.find((item) => item.id === imageId)
        setStockImage(image)
        setStockImageSelected(true);
        setStockQuery('null')
    }


    const handleDownloadImage = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = objectURL;
            link.download = "image";
            document.body.appendChild(link);
            link.click();
            link.remove();

            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Image downloaded successfully.",
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to download image. Please try again.",
            });
        }
    };
    return (
        <div className="right-container">
            <AnimationSpinner show={loading} />
            <div className="container h-75">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="card-container">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row align-items-end">
                                            <div className="mb-4 border-bottom dashed">
                                            <div className="d-flex justify-content-between align-items-center">
                                                        <h4 className="mb-4">AI Stock Image Generator</h4>
                                                        <Link to={'/ai-stock'}> <Button><IoMdArrowRoundBack /></Button></Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-6 mb-4">
                                                    <label htmlFor="keyword" className="text-md-right mb-2">Enter
                                                        Keyword Here</label>
                                                    <input
                                                        name="keyword"
                                                        type="search"
                                                        className="form-control rounded-3"
                                                        id="keyword"
                                                        placeholder="Enter keyword..."
                                                        required
                                                        value={stockQuery}
                                                        onChange={(e) => setStockQuery(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-2 col-md-6 mb-4">
                                                    <label htmlFor="orientation" className="text-md-right mb-2">Orientation
                                                    </label>
                                                    <select
                                                        className="form-control rounded-3"
                                                        value={orientation}
                                                        onChange={(e) => setOrientation(e.target.value)}
                                                    >
                                                        <option value="all" >All</option>
                                                        <option value="horizontal" id="Horizontal">Horizontal</option>
                                                        <option value="vertical">Vertical</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-2 col-md-6 mb-4">
                                                    <label htmlFor="image-type" className="text-md-right mb-2">Image Type</label>
                                                    <select
                                                        className="form-control rounded-3"
                                                        value={imageType}
                                                        onChange={(e) => setImageType(e.target.value)}
                                                    >
                                                        <option value="all">All</option>
                                                        <option value="photo">Photo</option>
                                                        <option value="illustration">Illustration</option>
                                                        <option value="vector">Vector</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="d-flex align-items-center justify-content-end mb-2 gap-2" id="reset-button-container">
                                                    <Button onClick={handleStockImageSearch} className="btn btn-secondary fw-bol d-flex align-items-center justify-content-center">
                                                        Generate
                                                    </Button>
                                                    <Button onClick={handleReset} className="btn btn-secondary fw-bol d-flex align-items-center justify-content-center">
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className={`row ${stockImagedData.length > 0 ? "" : 'd-none'}`}>
                                                <div className="col-lg-12">
                                                    <div id="show_image">
                                                        <div className="card-header">
                                                            <h5 className="card-title">Generated Images</h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 mx-auto">
                                                                <div className="mb-4">
                                                                    <div id="image" className="row mt-4">
                                                                        {stockImagedData.length > 0 ? (
                                                                            stockImagedData.map((image, index) => (
                                                                                <div className="col-lg-3 col-md-6" key={index}>
                                                                                    <div className="vc-box p-0">
                                                                                        <div className="vc-media">
                                                                                            <img src={image.previewURL} alt={`Stock ${index + 1}`} />
                                                                                            <div className="vc-btn">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-primary"
                                                                                                    onClick={() => handleDownloadImage(image.largeImageURL)}
                                                                                                >
                                                                                                    Download
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ) : null}
                                                                    </div>
                                                                    {stockImagedData.length > 0 ? (<div className="card-footer bg-transparent px-0">
                                                                        <button className="btn btn-primary ai-stock-load-more fw-600 rounded-3" type="button" id="loadmorebtn" onClick={handleLoadMore}>Load More....</button>
                                                                    </div>) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AiStockImages
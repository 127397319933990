import React, { useState, useContext } from "react";
import "../loginPage/LoginPage.styles.css";
import { Link } from "react-router-dom";
import loginImage from "../../assests/images/loginPage/loginImage.png"
import darkLogo from "../../assests/images/Logo/Black.png";
import { BsFillPersonFill } from "react-icons/bs";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import GetToken from "../../GetToken";
import { AppContext } from "../../context/AppContext";
import { fetchMenuPermissions } from "../../fetchMenuPermissions";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { handleInvalidToken } from '../../AuthUtils'
const ForgotPassword = ({ base_url }) => {
	const { setPermissions } = useContext(AppContext);

	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	
	const handleSubmit = (e) => {
		e.preventDefault();
		try {
			let isValid = true;
			if (!email) {
				Swal.fire({
					icon: "error",
					title: "Validation Error",
					text: "Email is required.",
				});
				isValid = false;
			}
			if (!isValid) {
				return;
			}
			setLoading(true);

			const formData = new FormData();
			formData.append("action", "forgotPasswordRequest");
			formData.append("email", email);

			axios
				.post(`${base_url}api/user/forgot-password`, formData)
				.then((response) => {
					if (response.data.status === true) {
						setLoading(false);
						Swal.fire({
							icon: "success",
							title: "Email Sent",
							text: "Check your inbox for the request.",
						});
					} else {
						setLoading(false);
						if (response.data.reset === true) {
							handleInvalidToken(navigate);
							return;
						}
						Swal.fire({
							icon: "error",
							title: "Error",
							text: response.data.message,
						});
					}
				});
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};
	return (
		<>
			<section className="login-box-wrapper">
				<AnimationSpinner show={loading} />
				<div className="container-fluid main-login-container">
					<div className="login-box">
						<div className="row justify-content-center h-100">
							<div className="left-login-container mx-auto">
								<div className="row w-100">
									<div className="col-lg-10 mx-auto">
										<div className="login-head mx-auto col-lg-12 text-left">
											<div className="login-logo">
												<img src={darkLogo} alt="Logo" />
											</div>
											<h2 className="title fs-lg-2">
												Welcome To CloneBuddy AI
											</h2>
											<h6 className="sub-title">
												Enter your email to receive a password reset link.
											</h6>
										</div>
										<div className="login-form">
											<form onSubmit={handleSubmit}>
												<div className="form-group">
													<div
														data-mdb-input-init
														className="form-outline "
													>
														<label htmlFor="form3Example1">
															Email
														</label>
														<div className="position-relative">
															<input
																type="text"
																id="form3Example1"
																className="form-control login-from-control mt-1"
																placeholder="Enter your Email"
																onChange={({ target: { value } }) =>
																	setEmail(value)
																}
															/>
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<button
															type="submit"
															data-mdb-button-init
															data-mdb-ripple-init
															className="btn login-button w-100"
														>
															send Request
														</button>
													</div>
												</div>
												<div className="forgot-link text-end">
													Go back to {" "}
													<Link
														to="/login"
													>
														login page
													</Link>
												</div>
											</form>
										</div>
									</div>
								</div>

							</div>
							<div className="right-login-container ">
								<div className="rlc-top">
									<h1>Easily clone your sites</h1>
									<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate magnam possimus deserunt temporibus! Illum sit voluptatem, nemo ut velit sint?</p>
								</div>
								<div className="rlc-bottom">
									<img src={loginImage} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import Swal from "sweetalert2";
import axios from "axios";

// react icons
import { IoSearch } from "react-icons/io5";
import { SlRefresh } from "react-icons/sl";
import { MdContentCopy } from "react-icons/md";
import { IoIosSave } from "react-icons/io";

const CopyWriterFrom = ({ base_url }) => {
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState("");
	const [data, setData] = useState([]);
	const [formattedData, setFormattedData] = useState([]);
	const { writerType } = useParams();
	const navigate = useNavigate();

	const handleFormSubmit = async (e) => {
		e?.preventDefault();
		if (!query.trim()) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please enter a keyword to generate content",
			});
			return;
		}

		setLoading(true);
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true)
			const formData = new FormData();
			formData.append("action", "aiWriterAction");
			formData.append("token", token);
			formData.append("writerType", writerType);
			formData.append("query", query);
			const response = await axios.post(
				`${base_url}api/user/ai-writer`,
				formData
			);
			if (response.data.status === true) {
				setLoading(false);
				const results = response.data.data.choices;
				const messagesArray = results.map(item => ({
					message: item.message.content
				}));
				setData(messagesArray)
			} else {
				Swal.fire({
					icon: "info",
					title: "No Results",
					text: "No content generated. Try different keywords.",
				});
			}

		} catch (error) {
			console.error(error);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "An error occurred while fetching content. Please try again.",
			});
		} finally {
			setLoading(false);  // Ensure that loading state is updated properly
		}
	};
	const formatContent = (content) => {
		let formattedContent = content
			.replace(/# (.*?)\n/g, '<h5>$1</h5>')
			.replace(/## (.*?)\n/g, '<h4>$1</h4>')
			.replace(/\n/g, '<p>$&</p>');
		return formattedContent;
	};
	const stripHTML = (htmlString) => {
		const doc = new DOMParser().parseFromString(htmlString, "text/html");
		return doc.body.textContent || "";
	};

	useEffect(() => {
		const processedData = data.map((item) => formatContent(item.message));
		setFormattedData(processedData);
	}, [data]);

	const handleContentCopy = ()=>{
		const plainText = formattedData.map(item => stripHTML(item)).join("\n");
		navigator.clipboard.writeText(plainText);
		Swal.fire({
            icon: "success",
            title: "Content Copied",
            text: "Content has been copied to your clipboard.",
        });
	}
	const handleReset = ()=>{
		setQuery("")
		setData([])
	}
	return (
		<>
			<div className="right-container">
				<AnimationSpinner show={loading} />
				<div className="container h-75">
					<div className='p-2 border-bottom dashed mb-3'>
						<h5>AI {writerType} Writer</h5>
					</div>
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								{/* <Link class="btn btn-primary mb-4" > Manage Copies </Link> */}
								<div class="card mb-4">
									<form id="aiContentForm" onSubmit={handleFormSubmit}>
										<div class="card-body">
											<div class="row">
												<div class="col-xl-12">
													<label for="search-input" className="mb-2">
														Generate AI Content
													</label>
													<textarea
														type="search"
														class="form-control rounded-3"
														id="input-text"
														placeholder="Insert Keyword/Topic"
														required=""
														style={{ height: "100px" }}
														value={query}
														onChange={(e) => setQuery(e.target.value)}
													></textarea>
												</div>
											</div>
											{/* <!-- <h6 class="text-danger mt-3" id="apierror"></h6> --> */}
										</div>
										<div class="card-footer">
											<div class="row px-3 flex-nowrap">
												<button
													type="submit"
													id="generate-button"
													class="custom-btn btn btn-primary rounded-3 waves-effect waves-light me-2"
												>
													<IoSearch /> Generate
												</button>
												<div
													class="custom-btn btn btn-dark rounded-3 waves-effect waves-light me-2"
													onClick={handleReset}
												>
													Reset
												</div>
											</div>
										</div>
									</form>
								</div>

								<div class={`card mb-4 ${data == "" ? "d-none" : "d-block"}`}>
									<div class="card-body">
										<div class="row">
											<div class="col-lg-12 mx-auto">
												<div class="mb-4">
													<div id="textbox">
														<div class="ai-writer-content-div">
															<div class="card-header d-flex align-items-center justify-content-between flex-wrap">
																<div class="writer-title">
																	<h5 class="mb-0">Output</h5>
																</div>
																<div class="right-buttons">
																	{/* <button
																		title="Regenerate content"
																		type="button"
																		class="btn btn-primary action-button fs-5 px-3 py-1 btn-sm me-2 mb-2"
																		id="regenerate-button"
																	>
																		<SlRefresh />
																	</button> */}
																	<button
																		title="Copy Content"
																		type="button"
																		class="btn btn-dark action-button fs-5 px-3 py-1 btn-sm me-2 mb-2"
																		id="copy-button"
																		onClick={handleContentCopy}
																	>
																		<MdContentCopy />
																	</button>
																	{/* <button
																		title="Save Content"
																		type="button"
																		class="btn btn-secondary action-button fs-5 px-3 py-1 btn-sm me-2 mb-2"
																		id="save-button"
																	>
																		<IoIosSave />
																	</button> */}
																</div>
															</div>
															<div class="card-body">
																<div id="generated-text" dangerouslySetInnerHTML={{ __html: formattedData }} />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CopyWriterFrom;

import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { FaDownload } from "react-icons/fa6";
import { Link } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";

const AiStockVideos = () => {
  const [videoData, setVideoData] = useState([]);
  const [videoQuery, setVideoQuery] = useState("");
  const [videoType, setVideoType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const handleVideoSearch = async (e) => {
    e.preventDefault();

    if (!videoQuery.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Enter Keyword To Generate Video",
      });
      return;
    }

    setLoading(true);
    setVideoData([]);
    setCurrentPage(1);

    await fetchVideos(videoQuery, videoType, 1);
  };

  const fetchVideos = async (query, type, page) => {
    try {
      const url = `https://pixabay.com/api/videos/?key=43529301-48bb67a4a3176c73ad9bd51f3&q=${query}&video_type=${type}&page=${page}&per_page=12`;

      const response = await axios.get(url);

      if (response.data.total === 0 && page === 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Result Not Found! Please Try Again With Different Keywords",
        });
        setHasMore(false);
        return;
      }

      setVideoData((prevVideos) => [...prevVideos, ...response.data.hits]);
      setHasMore(response.data.hits.length === 12);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while fetching videos. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setLoading(true);
    await fetchVideos(videoQuery, videoType, nextPage);
  };

  const handleReset = () => {
    setVideoQuery("");
    setVideoData([]);
    setCurrentPage(1);
    setHasMore(false);
  };

  const handleDownloadVideo = (url) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = objectURL;
        a.download = "Video.mp4";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(objectURL);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Video Downloaded Successfully.",
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to Download Video. Please Try Again.",
        });
      });
  };

  return (
    <div className="right-container">
      <div className="container h-75">
        <div className="row">
          <div className="col-sm-12">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card-container">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-4 border-bottom dashed">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="mb-4">Search AI Stock Videos</h4>
                          <Link to={'/ai-stock'}> <Button><IoMdArrowRoundBack /></Button></Link>
                        </div>
                      </div>
                      <form onSubmit={handleVideoSearch}>
                        <div className="row align-items-end">
                          <div className="col-lg-8 mb-4">
                            <label htmlFor="videoKeyword" className="mb-2">Enter Keyword Here</label>
                            <input
                              type="search"
                              id="videoKeyword"
                              className="form-control rounded-3"
                              placeholder="Enter keyword..."
                              value={videoQuery}
                              onChange={(e) => setVideoQuery(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-lg-4 mb-4">
                            <label htmlFor="videoType" className="mb-2">Video Type</label>
                            <select
                              id="videoType"
                              className="form-control rounded-3"
                              value={videoType}
                              onChange={(e) => setVideoType(e.target.value)}
                            >
                              <option value="all">All</option>
                              <option value="film">Film</option>
                              <option value="animation">Animation</option>
                            </select>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end gap-2">
                          <Button type="submit" className="btn btn-primary fw-bold">
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Generate"
                            )}
                          </Button>
                          <Button onClick={handleReset} className="btn btn-secondary fw-bold">
                            Reset
                          </Button>
                        </div>
                      </form>

                      {videoData.length > 0 && (
                        <div className="mt-4">
                          <h5 className="card-title">Generated Videos</h5>
                          <div className="row">
                            {videoData.map((video, index) => (
                              <div className="col-lg-4 col-md-6" key={index}>
                                <div className="aivideo-box">
                                  <div className="ai-media">
                                    <video
                                      controls
                                      style={{ width: "100%" }}
                                      src={video.videos.small.url}
                                    />
                                  </div>
                                  <div className="icon-box p-1">
                                    <Button
                                      className="btn btn-primary"
                                      onClick={() => handleDownloadVideo(video.videos.small.url)}
                                    >
                                      <FaDownload />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          {hasMore && (
                            <div className="text-start mt-3">
                              <Button
                                className="btn btn-primary "
                                onClick={handleLoadMore}
                              >
                                Load More...
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiStockVideos;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import AnimationSpinner from "../../AnimationSpinner";
import emptyIcon from '../../assests/images/emptyIcon.png'
import { truncateTextByCharacters } from '../../util'

// bootstrap imports
import { Pagination } from "react-bootstrap";

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEllipsisVertical } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
const ListVideos = ({ base_url }) => {
	const [videos, setVideos] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [showNameModal, setShowNameModal] = useState(false);
	const [totalItems, setTotalItems] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedVideo, setSelectedVideo] = useState("");
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	// constants for range option----------------------------

	const [itemsPerPage, setItemsPerPage] = useState(10);

	// sweetalert function--------------------------------------------------------
	function Sweetalert(field) {
		Swal.fire({
			icon: "error",
			title: "Validation Error",
			text: field + " is required!",
		});
	}

	// core functons--------------------------------------------------------

	// fetching video list--------------------------------------------------
	const fetchVideoList = async () => {
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			if (searchTerm == '') {
				setLoading(true);
			}
			const formData = new FormData();
			formData.append("action", "get_video_clone_data_api");
			formData.append("token", token);
			formData.append("offset", currentPage);
			formData.append("limit", itemsPerPage ? itemsPerPage : 10);
			formData.append("search", searchTerm);
			const response = await axios.post(
				`${base_url}api/user/video/get-data-api`,
				formData
			);
			if (response.data.status === true) {
				setLoading(false);
				const clonedVideos = response.data.data.video_cloning_data.map((video) => {
					return video;
				});

				setVideos(clonedVideos || []);
				setTotalItems(response.data.data.pagination.total || 0);
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Error",
					text: response.data.message,
				});

			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	const closeModel = () => {
		setShowNameModal(false);
	};

	const handleEditNameClick = (id) => {
		const video = videos.find((video) => video.id === id);
		if (video) {
			setSelectedVideo(video); // Set the selected video details
			setShowNameModal(true);
		}
	};

	// updatin video function--------------------------------------------------
	const handleVideoUpdate = async (e, var_video) => {
		e.preventDefault();
		setShowNameModal(false);
		try {
			const token = GetToken();
			if (!token) {
				handleInvalidToken(navigate);
				return;
			}
			setLoading(true);
			const formData = new FormData();
			formData.append("action", "video_update_api");
			formData.append("token", token);
			formData.append("video_id", var_video.id);
			formData.append("title", var_video.title);
			formData.append("description", var_video.video_description);

			const response = await axios.post(
				`${base_url}api/user/video/update-api`,
				formData
			);
			if (response.data.status === true) {
				setLoading(false);
				setSelectedVideo(null);
				fetchVideoList();
				Swal.fire({
					icon: "success",
					title: "video updated",
					text: "Your video details have been updated successfully.",
				});
				setTimeout(() => {
					window.location.reload();
				}, 2000); // 3000 milliseconds = 3 seconds
			} else {
				setLoading(false);
				if (response.data.reset === true) {
					handleInvalidToken(navigate);
					return;
				}
				Swal.fire({
					icon: "error",
					title: "Fauled to update video details. Please try again. ",
					text: response.data.message,
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text:
					error.response?.data?.message ||
					error.message ||
					"Something went wrong. Please try again.",
			});
		}
	};

	// deleting agency function--------------------------------------------------
	const handleDelete = async (id) => {
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this item!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		});

		if (result.isConfirmed) {
			try {
				const token = GetToken();
				if (!token) {
					handleInvalidToken(navigate);
					return;
				}
				setLoading(true);
				const formData = new FormData();
				formData.append("action", "video_delete_api");
				formData.append("token", token);
				formData.append("video_id", id);
				const response = await axios.post(
					`${base_url}api/user/video/delete-api`,
					formData
				);

				if (response.data.status === true) {
					setLoading(false);
					setVideos(videos.filter((item) => item.id !== id));
					Swal.fire("Deleted!", "Your Video has been deleted.", "success");
					setTimeout(() => {
						window.location.reload();
					}, 2000); // 3000 milliseconds = 3 seconds
				} else {
					setLoading(false);
					if (response.data.reset === true) {
						handleInvalidToken(navigate);
						return;
					}
					Swal.fire({
						icon: "error",
						title: "Error",
						text: response.data.message,
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire("Error", "Failed to delete Video", "error");
			}
		}
	};

	useEffect(() => {
		fetchVideoList();
	}, [
		currentPage,
		navigate,
		base_url,
		searchTerm,
		itemsPerPage,
	]);

	useEffect(() => {
		setCurrentPage(1)
	}, [itemsPerPage])

	const totalPages = Math.ceil(totalItems / itemsPerPage);

	return (
		<div className="container h-100">
			<AnimationSpinner show={loading} />
			<div className="right-container">
				<div className="row">
					<div className="col-lg-12">
						<div className="form-card">
							<div className="form-body">
								<div className="row">
									<div className="col-lg-12">
										<div className="head-part d-flex align-items-center justify-content-between mx-0">
											<h3 className="title">Manage Videos</h3>
											<div>
												<Link to="/ai-video-clone">
													<button type="button" className="btn btn-primary mr-3 mb-2">
														Clone New Video
													</button>
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div>
										<div id="dataTable_agency_user_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
											<div className="row align-items-center">
												<div className="col-sm-12 col-md-6">
													<div className="dataTables_length" id="dataTable_agency_user_length">
														<label className="d-flex align-items-center">
															show
															<select
																id="formRange"
																className="form-select custom-form-select"
																value={itemsPerPage}
																onChange={(e) => setItemsPerPage(e.target.value)}
															>
																<option value="10">10</option>
																<option value="25">25</option>
																<option value="50">50</option>
															</select>
															entries
														</label>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div id="dataTable_agency_user_filter" className="dataTables_filter">
														<label className="form-label w-100">
															<input
																type="search"
																className="form-control custom-form-control mt-2"
																placeholder="Search..."
																aria-controls="dataTable_agency_user"
																value={searchTerm}
																onChange={(e) => setSearchTerm(e.target.value)}
															/>
														</label>
													</div>
												</div>
											</div>

											{videos.length > 0 ?
												(
													<div className="row">
														<div className="col-sm-12">
															<div className="table-responsive">
																<table
																	id="dataTable_agency_user"
																	className="table table-striped w-100 dataTable no-footer"
																	role="grid"
																	aria-describedby="dataTable_agency_user_info"
																	style={{ width: "1067px" }}
																>
																	<thead className="table-head">
																		<tr role="row">
																			<th>Video Title</th>
																			<th>Preview</th>
																			<th>Created Date</th>
																			<th>Action</th>
																		</tr>
																	</thead>
																	<tbody>
																		{videos.map((video, index) => (
																			<tr role="row" className={index % 2 == 1 ? "even" : "odd"} key={video.ids}>
																				<td>{truncateTextByCharacters(video.title, 30)}</td>
																				<td><a href={`https://clonebuddyai.com/api/player-pre/${video.slug}`} target="_blank">https://clonebuddyai.com/player-pre/{video.slug}</a></td>
																				<td>{video.created}</td>

																				<td>
																					<Dropdown>
																						<Dropdown.Toggle id="dropdown-no-caret" as="button" className="btn btn-light">
																							<FaEllipsisVertical />
																						</Dropdown.Toggle>
																						<Dropdown.Menu>
																							<Dropdown.Item onClick={() => handleEditNameClick(video.id)}>
																								<FaRegEdit className="f17 text-success me-2 mb-1" />
																								Edit

																							</Dropdown.Item>
																							<Dropdown.Item onClick={() => handleDelete(video.id)}>
																								<RiDeleteBin6Fill className="delete-icon" />
																								Delete
																							</Dropdown.Item >
																						</Dropdown.Menu>
																					</Dropdown>
																				</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												) :
												(<div className="row mt-4 mb-5">
													<div className="col-lg-5 col-md-8 mx-auto">
														<div className="empty-box">
															<div className="card-body">
																<div className="list-empty-box">
																	<div className="icon">
																		<img src={emptyIcon} alt="No data available" />
																	</div>
																	<p>No data available</p>
																</div>
															</div>
														</div>
													</div>
												</div>)}
											<div className="row justify-content-between align-items-center mt-4 mb-4">
												<div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
													<Pagination className="mb-0">
														<Pagination.Prev
															onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
															disabled={currentPage === 1}
														/>
														{[...Array(totalPages)].slice(Math.floor((currentPage - 1) / 10) * 10, Math.floor((currentPage - 1) / 10) * 10 + 10).map((_, index) => (
															<Pagination.Item
																key={index + 1 + Math.floor((currentPage - 1) / 10) * 10}
																active={index + 1 + Math.floor((currentPage - 1) / 10) * 10 === currentPage}
																onClick={() => setCurrentPage(index + 1 + Math.floor((currentPage - 1) / 10) * 10)}
															>
																{index + 1 + Math.floor((currentPage - 1) / 10) * 10}
															</Pagination.Item>
														))}
														<Pagination.Next
															onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
															disabled={currentPage === totalPages}
														/>
													</Pagination>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showNameModal && (
				<div className="modal d-block">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Video Details</h5>
								<button type="button" className="close" onClick={closeModel}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form onSubmit={(e) => handleVideoUpdate(e, selectedVideo)}>
									<div className="mb-3">
										<label className="form-label">Video Title</label>
										<input
											type="text"
											className="form-control"
											value={selectedVideo.title}
											onChange={(e) =>
												setSelectedVideo((prev) => ({
													...prev,
													title: e.target.value,
												}))
											}
										/>
									</div>

									<div className="mb-3">
										<label className="form-label">Description</label>
										<textarea
											className="form-control"
											value={selectedVideo.video_description || ''}
											onChange={(e) =>
												setSelectedVideo((prev) => ({
													...prev,
													video_description: e.target.value,
												}))
											}
											rows="8"
										/>
									</div>

									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</form>

							</div>
						</div>
					</div>
				</div>
			)}
		</div>

	);
};

export default ListVideos;
import React, { useState, useContext } from "react";
import "./LoginPage.styles.css";
import { Link } from "react-router-dom";
import loginImage2 from '../../assests/images/loginPage/CloneBuddy_AI.jpg'
import darkLogo from "../../assests/images/Logo/Black.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AnimationSpinner from "../../AnimationSpinner";
import GetToken from "../../GetToken";
import { AppContext } from "../../context/AppContext";
import { fetchMenuPermissions } from "../../fetchMenuPermissions";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";



const LoginPage = (props) => {
	const { setPermissions, customDomain } = useContext(AppContext);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (email.trim() === "" || password.trim() === "") {
			const text =
				email.trim() === "" && password.trim() === ""
					? "Email and Password are required."
					: email.trim() === ""
						? "Email is required."
						: "Password is required.";

			Swal.fire({
				icon: "error",
				title: "Warning...",
				text: text,
			});
			return;
		}
		setLoading(true);
		const auth = new FormData();
		auth.append("email", email);
		auth.append("password", password);

		axios
			.post(`${props.base_url}authentication`, auth)
			.then((res) => {
				if (res.data.status === true) {
					localStorage.setItem("aistafftoken", res.data.token);
					localStorage.setItem("username", res.data.username);
					localStorage.setItem("avatar", res.data.avatar);
					localStorage.setItem("email", res.data.email);
					const token = GetToken();
					fetchMenuPermissions(token, props.base_url, setPermissions);
					setTimeout(() => {
						navigate("/dashboard", { replace: true });
						setLoading(false);
					}, 500);
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Login Failed...",
						text: res.data.message,
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				// console.error('Error:', err);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "An error occurred during login. Please try again.",
				});
			});
	};

	return (
		<>
			{customDomain == null
			 ? (<section className="login-box-wrapper">
				<AnimationSpinner show={loading} />
				<div className="container-fluid main-login-container">
					<div className="login-box">
						<div className="row justify-content-center h-100">
							<div className="left-login-container mx-auto">
								<div className="row w-100">
									<div className="col-lg-10 mx-auto">
										<div className="login-head mx-auto col-lg-12 text-left">
											<div className="login-logo mx-auto">
												<img src={darkLogo} alt="Logo" />
											</div>
											<h3 className="title fs-lg-2">
												Welcome To CloneBuddy AI
											</h3>
											<h6 className="sub-title">

												Sign into your account by entering information below
											</h6>
										</div>
										<div className="login-form">
											<form onSubmit={handleSubmit}>
												<div className="form-group">
													<div
														data-mdb-input-init
														className="form-outline "
													>
														<label htmlFor="form3Example1">
															Email
														</label>
														<div className="position-relative">
															<input
																type="text"
																id="form3Example1"
																className="form-control login-from-control mt-1"
																placeholder="Enter your Email"
																onChange={({ target: { value } }) =>
																	setEmail(value)
																}
															/>
														</div>
													</div>
												</div>
												<div className="form-group">
													<div
														data-mdb-input-init
														className="form-outline"
													>
														<label htmlFor="form3Example2">
															Password
														</label>
														<div className="position-relative">
															<input
																type={!showPassword ? "password" : "text"}
																id="form3Example2"
																className="form-control login-from-control mt-1"
																placeholder="Enter your Password"
																onChange={({ target: { value } }) =>
																	setPassword(value)
																}
															/>
															<div
																className="login-input-icon position-absolute"
																onClick={handleShowPassword}
																style={{ cursor: "pointer" }}
															>
																{showPassword ? (
																	<FaRegEyeSlash />
																) : (
																	<FaRegEye />
																)}
															</div>
														</div>
													</div>
												</div>

												<div className="col-md-12">
													<div className="form-group">
														<button
															type="submit"
															data-mdb-button-init
															data-mdb-ripple-init
															className="btn login-button w-100"
														>
															Login
														</button>
													</div>
												</div>
												<div className="forgot-link">
													Need help{" "}
													<Link
														to="/forgot-password-request"
													>
														Forgot Password?
													</Link>
												</div>
											</form>
										</div>
									</div>
								</div>

							</div>
							<div className="right-login-container ">
								<div className="rlc-top">
									<h1>Easily clone your sites</h1>
									<p>Effortlessly clone, migrate, and host any website. Log in to unlock the power of seamless web management with AI!</p>
								</div>
								<div className="rlc-bottom">
									<img src={loginImage2} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>) : null}
		</>
	);
};

export default LoginPage;

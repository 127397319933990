import React, { useState } from 'react'
import AnimationSpinner from '../../AnimationSpinner'
import { Link, useNavigate } from 'react-router-dom'

const AIStocks = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const cards = [
        { name: "Images", icon: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR8LZaEBd-k1vtXUDW31eR2u3bBtCawsBeYwmf0sDQqOTLctdtJ", type: "images" },
        { name: "Videos", icon: "https://img.freepik.com/free-vector/customer-behavior-concept-illustration_114360-7445.jpg", type: "videos" },
        { name: "GIFs", icon: "https://play-lh.googleusercontent.com/XGqMG4PypvoWNVfkx7BUBbHybfNrKO4vSa_JWVATQOVTuM1SvtG8NtkExAsRVpA1rGc=w240-h480-rw", type: "gifs" },
        { name: "Audios", icon: "https://img.freepik.com/free-vector/youtube-tutorial-concept-illustration_114360-2807.jpg?w=360", type: "audios" }
    ]

    const handleCardClick = (type) => {
        console.log("Selected type:", type)
        navigate(`/ai-stock/${type}`)
    }

    return (
        <div className="right-container">
            <AnimationSpinner show={loading} />
            <div className="container h-75">
                <div className='p-2 border-bottom dashed mb-3'>
                    <h3>AI Stock</h3>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            {/* Content type options shown as boxes */}
                            <div id="contentOptions" className="card-body p-4">
                                <div className="row g-4">
                                    {cards.map((card, index) => (
                                        <div key={index} className="col-md-6 col-lg-3">
                                            <div  className="card border rounded shadow-sm hover-shadow-lg">
                                                <div className="card-body ai-stock-card text-center">
                                                    <div className="ai-stock-icon-box mb-3">
                                                        <img src={card.icon} alt={card.name} className="img-fluid" />
                                                    </div>
                                                    <div className='d-flex gap-2 justify-content-between align-items-center mt-4'>
                                                        <h5 className="card-title ai-stock-card-title mb-0">{card.name}</h5>
                                                        <button
                                                            onClick={() => handleCardClick(card.type)}
                                                            className="btn btn-primary ai-stock-button">
                                                            Choose
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AIStocks

import React, { useContext } from 'react'
import { AppContext } from "../../context/AppContext";
import Swal from 'sweetalert2';
import './WhiteLabel.styles.css'

const WhiteLabel = () => {
	const { menuFullPermission, packageAccess, base_url } = useContext(AppContext);
	const downloadZip = () => {
		if (menuFullPermission && packageAccess.includes('whitelabel')) {
			const zipUrl = base_url + 'whitelabel/wl_clonebuddyai.zip';
			const link = document.createElement('a');
			link.href = zipUrl;
			link.download = 'clonebuddyai.zip';
			link.click();
			Swal.fire({
				title: "Download Successful!",
				text: "Your download will start shortly!",
				icon: "success"
			});
		} else {
			Swal.fire({
				title: "Permission Denied",
				text: "You do not have permission to download the Whitelabel code.",
				icon: "error"
			});
		}
	};
	return (
		<div className="right-container">
			<div className="content">
				<div className="container-fluid">
					{/* start page title  */}
					<div className="row mb-2">
						<div className="col-12 ">
							<div className="page-title-box w-100">
								<h4 className="page-title">Whitelabel</h4>
							</div>
						</div>
					</div>
					{/* end page title  */}
					{/* Card  */}
					<div className="row">
						<div className="col-md-12">
							<div className="card w-100">
								<div className="head-part mb-0">
									<h6 className="profile-heading">Whitelabel</h6>
								</div>
								<div className="card-body">
									{/* Content */}
									<h4 className="description-text">
										The CloneBuddy AI Whitelabel code is now ready for download. Please click the button below to download it, or submit a ticket on our support desk if you need further assistance.
										<div class="row mt-4">
											<div className="col-lg-12">
												<button id="downloadBtn" class="btn btn-primary me-2" onClick={downloadZip}>Download Whitelabel Code</button>
												{/* New button with static link on the right */}
												<a href="https://imtopvendor.tawk.help/" target="_blank" rel="noopener noreferrer">
													<button className="btn btn-secondary">
														Visit Support Desk
													</button>
												</a>
											</div>
										</div>
									</h4>
									{/* End Content */}
								</div>
							</div>
						</div>
					</div>
					{/* End Card */}
				</div>
			</div>
		</div>
	)
}

export default WhiteLabel

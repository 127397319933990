import React, { useState, useEffect } from 'react'
import AnimationSpinner from '../../AnimationSpinner'
import GetToken from '../../GetToken'
import { handleInvalidToken } from '../../AuthUtils'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from 'axios';

const Domain = ({ base_url }) => {
    const [loading, setLoading] = React.useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false);               // flag stops user to immediately submit again
    const [firstSubmit, setFirstSubmit] = useState(false);              //to show errros only after first submission
    const [error, setError] = useState("")
    const [data, setData] = useState({
        subDomain: '',
        description: ''
    })

    const navigate = useNavigate();
    // functions 
    const handleSetValues = (item, value) => {
        setData({ ...data, [item]: value })
    }

    const validation = (submission) => {
        if (!firstSubmit && !submission) {
            return true;      // is user is not even submitted form once validation will not run and dont show error when page load
        }
        let hasErrors = false;
        let validationErrors = ""          // for returning value to avoid asynchronous operation
        if (!data.subDomain || data.subDomain.trim() === "") {
            validationErrors = "Sub domain is required";
            hasErrors = true;
        }
        if (hasErrors) {
            setError(validationErrors)
        } else {
            setError("")
        }
        return hasErrors;
    };

    // core functions for handling submit and reset form
    const handleSubmit = (e) => {
        e.preventDefault();

        // Block submission if the form is already submitted recently
        if (isSubmitted) {
            return; // Prevent further submissions within 2 seconds
        }

        if (!firstSubmit) {
            setFirstSubmit(true); // Mark first submission
        }

        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }

            const hasError = validation(true);
            if (hasError) {
                return;
            }

            setLoading(true);
            setIsSubmitted(true); // Set isSubmitted to true to prevent re-submission

            const formData = new FormData();
            formData.append("token", token);
            formData.append("sub_domain_name", data.subDomain);
            formData.append("description", data.description);
            formData.append("action", 'create-domain-action');

            axios
                .post(`${base_url}api/user/create-domain-action`, formData)
                .then((response) => {
                    setLoading(false);
                    setIsSubmitted(false); // Reset isSubmitted after the response is processed

                    if (response.data.status === true) {
                        Swal.fire({
                            icon: "success",
                            title: "Domain sent",
                            text: "Your details have been submitted successfully.",
                        });

                        // Reset form data and error state
                        setData({
                            subDomain: '',
                            description: ''
                        });
                        setError('');

                        // Redirect after 3 seconds
                        setTimeout(() => {
                            // Replace '/target-page' with your desired route
                            navigate('/manage-domain');
                        }, 2000);

                    } else {
                        if (response.data.reset === true) {
                            handleInvalidToken(navigate);
                            return;
                        }
                        // Check if it's a validation message
                        if (response.data.validation === true) {
                            Swal.fire({
                                icon: "warning", // Show as warning
                                title: "Validation Warning",
                                text: response.data.message, // Validation message
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: response.data.message, // General error message
                            });
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: "Network Error",
                        text: "An unexpected error occurred. Please try again.",
                    });
                });

            // Reset isSubmitted state after 2 seconds to allow next submission
            setTimeout(() => {
                setIsSubmitted(false);
            }, 2000); // 2 seconds
        } catch (error) {
            setLoading(false);
            setIsSubmitted(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        setData({
            subDomain: '',
            description: ''
        })
        setError('');
    };

    useEffect(() => {
        validation();
    }, [data.subDomain, data.description, firstSubmit]);

    return (
        <div className="container h-100">
            <AnimationSpinner show={loading} />
            <div className="right-container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 mx-auto">
                        <div className="form-card">
                            <div className="form-body">
                                <div className="head-part">
                                    <h6 className="title">Domain Page</h6>
                                </div>
                                <form
                                    acceptCharset="utf-8"
                                    autoComplete="off"
                                >
                                    <div className="card-body">
                                        <div className="row form-group mb-0">
                                            <div className="col-md-10 mb-3 mx-auto">
                                                <label className="form-label">
                                                    Sub Domain Name{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter your sub domain here..."
                                                    className="form-control custom-form-control"
                                                    value={data.subDomain}
                                                    onChange={(e) => handleSetValues("subDomain", e.target.value)}
                                                    required
                                                />
                                                {error != '' ? (<span className="text-danger">{error}</span>) : null}
                                            </div>

                                            <div className="col-md-10 mb-3 mx-auto">
                                                <label className="form-label">
                                                    Description{" "}
                                                </label>
                                                <textarea
                                                    className="form-control custom-form-control"
                                                    name="postContent"
                                                    placeholder='Enter description...'
                                                    value={data.description}
                                                    rows={10}
                                                    onChange={(e) => handleSetValues("description", e.target.value)}
                                                    required
                                                    style={{ height: "100px" }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-10 mx-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary px-4 mt-2 mb-2 me-4"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            className="btn btn-primary px-4 mt-2 mb-2"
                                            onClick={handleReset}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Domain
import React, { useState, useEffect } from "react";
import './WebSiteBuilder.css'
import AnimationSpinner from "../../AnimationSpinner";
import emptyIcon from "../../assests/images/emptyIcon.png";
import { Link } from "react-router-dom";
import { Card, Button, Modal, Row, Col, Pagination, Form, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import GetToken from "../../GetToken";
import { handleInvalidToken } from "../../AuthUtils";
import { useNavigate } from "react-router-dom";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import Select from 'react-select';

// react-icons
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { truncateTextByWords } from "../../util";
const WebSiteBuilder = ({ base_url }) => {
    const [items, setItems] = useState([]);
    const [categoryItems, setCategoryItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [fetchedDomainDetails, setFetchedDomains] = useState([]);
    const [options, setOptions] = useState([]);
    const [subdomain, setSubdomain] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [tempToken, setTempToken] = useState(null);  // Store tempToken
    const [userSites, setUserSites] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);  // Track selected template ID
    const navigate = useNavigate();

    // sates responsible for  model
    const [showDomainModel, setShowDomainModel] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState("");
    const [pageTitle, setPageTitle] = useState("")


    const DESCRIPTION_LIMIT = 500;
    const TITLE_LIMIT = 100;

    const fetchDefaultTemplates = async (category = "") => {
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            setLoading(true)

            const formData = new FormData();
            formData.append("action", "default_templates_api");
            formData.append("token", token);
            formData.append("page", currentPage);
            formData.append("limit", itemsPerPage);
            if (category) {
                formData.append("category", category);  // Send the selected category
            }
            const response = await axios.post(
                `${base_url}api/default_templates_api`,
                formData
            );
            if (response.data.status) {
                setLoading(false);
                setItems(response.data.data.default_template_list);
                setTotalItems(response.data.data.pagination.total);
                setCategoryItems(response.data.data.default_templates_category);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.message || "Failed to fetch data.",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };
    useEffect(() => {
        fetchDefaultTemplates(selectedCategory ? selectedCategory.value : "");
    }, [currentPage, itemsPerPage, selectedCategory]);


    const handleSiteView = (slug, subdomain, customdomain) => {
        const viewUrl = customdomain
            ? `https://${customdomain}/${slug}`
            : `https://${subdomain}.clonebuddyai.com/${slug}`;

        window.open(viewUrl, "_blank");
    };

    const handleCategoriesChange = (selceted) => {
        setSelectedCategory(selceted)
        setCurrentPage(1);
    }
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // handeling model features
    const handleDomainModelClose = () => setShowDomainModel(false);

    const handleNextClick = async () => {
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            setLoading(true);
            const formData = new FormData();
            formData.append("action", "user_submit_template");
            formData.append("token", token);
            formData.append("template_id", selectedTemplateId);
            formData.append("domain_id", subdomain.value);
            formData.append("page_title", pageTitle);

            const response = await axios.post(`${base_url}api/user/user_submit_template`, formData);
            if (response.data.status === true) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Your site has been successfully created using the selected template!",
                });
                navigate('/manage-site');
                handleDomainModelClose();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.message || "Failed to create your site. Please try again.",
                });
                handleDomainModelClose();
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.message || "Something went wrong. Please try again.",
            });
        }
    };

    const handleEmbedModelShow = (templateId) => {
        setSelectedTemplateId(templateId);  // Set selected template ID
        setShowDomainModel(true);
    };
    const handleDomainChange = (selceted) => {
        setSubdomain(selceted)
    }
    const fetchDomains = async () => {
        try {
            const token = GetToken();
            if (!token) {
                handleInvalidToken(navigate);
                return;
            }
            setLoading(true)
            const formData = new FormData();
            formData.append("action", "get_domains");
            formData.append("token", token);
            const response = await axios.post(
                `${base_url}api/user/get-domain-details`,
                formData
            );
            if (response.data.status === true) {
                setLoading(false);
                setFetchedDomains(response.data.domains);
                const domainOptions = response.data.domains.map(domain => ({
                    label: domain.sub_domain_name,
                    value: domain.id
                }));

                setOptions((options) => (
                    [{
                        label: "Select Domain",
                        value: null
                    },
                    ...domainOptions
                    ]
                )); // Update options state with domain names
            } else {
                setLoading(false)
                if (response.data.reset === true) {
                    handleInvalidToken(navigate);
                    return;
                }
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.message,
                });
            }
        } catch (error) {
            setLoading(false)
            Swal.fire({
                icon: "error",
                title: "Error",
                text:
                    error.response?.data?.message ||
                    error.message ||
                    "Something went wrong. Please try again.",
            });
        }
    };
    useEffect(() => {
        fetchDomains();
    }, []);

    return (
        <div className="right-container">
            <AnimationSpinner show={loading} />
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-card">
                        <div className="form-body">
                            <div className="head-part w-100">
                                <Link to={'/manage-site'} className="btn btn-primary">Manage Websites</Link>
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-lg-12 mt-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="title"> Choose Website </h4>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                name="categories"
                                                options={categoryItems.map((category) => ({
                                                    value: category.category_title, // Assuming category has an id field
                                                    label: category.category_title, // Assuming category has a name field
                                                }))}
                                                value={selectedCategory}
                                                onChange={handleCategoriesChange}
                                                placeholder={"Choose a category"}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        width: "200px",
                                                        minHeight: "36px",
                                                        fontSize: "13px",
                                                        borderRadius: "8px",
                                                        border: state.isFocused
                                                            ? "1px solid rgb(88, 88, 88)"
                                                            : "1px solid rgb(88, 88, 88)",
                                                        boxShadow: state.isFocused ? null : null,
                                                    }),
                                                    indicatorContainer: (provided) => ({
                                                        ...provided,
                                                        padding: "2px 8px",
                                                    }),
                                                    menu: (base) => ({
                                                        ...base,
                                                        fontSize: "14px", // Change the font size of the menu
                                                    }),
                                                    placeholder: (base) => ({
                                                        ...base,
                                                        fontSize: "16px",
                                                    }),
                                                }}
                                            />

                                        </div>
                                    </div>

                                </div>
                            </div>


                            {items.length > 0 ? (
                                <Row>
                                    {items.map((item, index) => {
                                        return (
                                            <div key={index} className="col-lg-4 col-md-6 mb-4">
                                                <div className="all-site-template">
                                                    <div className="template-media">
                                                        <img
                                                            src={`https://clonebuddyai.com/api/${item.thumbnail_path}`}
                                                            className="w-100"
                                                            alt={`Thumbnail for ${item.thumbnail_path}`}
                                                        />
                                                    </div>
                                                    <div className="template-content">
                                                        <p className="card-text pos-tops mb-0"><span className=" badge-style bg-primary text-capitalize">{item.category}</span>
                                                        </p>
                                                        <p className="d-block template-title mb-0">
                                                            <span className="text-capitalize"> {item.title}</span>
                                                        </p>
                                                        <div className="text-center over-layer">
                                                            <a className="btn btn-primary rounded-3 fw-bold px-4" onClick={() => handleEmbedModelShow(item.id)}>Choose</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Row>
                            ) : (
                                <div className="row mt-4 mb-5">
                                    <div className="col-lg-5 col-md-8 mx-auto">
                                        <div className="empty-box">
                                            <div className="card-body">
                                                <div className="list-empty-box">
                                                    <div className="icon">
                                                        <img src={emptyIcon} alt="No data available" />
                                                    </div>
                                                    <p>No data available</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="pagination-container w-full d-flex justify-content-end align-items-center border-top pt-2 mt-4">
                                <Pagination className="mb-0">
                                    <Pagination.Prev
                                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                        disabled={currentPage === 1}
                                    />
                                    {[...Array(totalPages)].slice(Math.floor((currentPage - 1) / 10) * 10, Math.floor((currentPage - 1) / 10) * 10 + 10).map((_, index) => (
                                        <Pagination.Item
                                            key={index + 1 + Math.floor((currentPage - 1) / 10) * 10}
                                            active={index + 1 + Math.floor((currentPage - 1) / 10) * 10 === currentPage}
                                            onClick={() => setCurrentPage(index + 1 + Math.floor((currentPage - 1) / 10) * 10)}
                                        >
                                            {index + 1 + Math.floor((currentPage - 1) / 10) * 10}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                    />
                                </Pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showDomainModel} onHide={handleDomainModelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Up Your Site</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please select a domain and provide a page title for your new site.</p>
                    <div className="col-md-10 mb-3 mx-auto">
                        <label className="form-label">
                            Select Domain
                            <span className="text-danger">*</span>
                        </label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={options[0]}
                            value={subdomain}
                            isSearchable={true}
                            name="countries"
                            options={options}
                            onChange={handleDomainChange}
                            placeholder={"select domain"}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    minHeight: "36px",
                                    fontSize: "13px",
                                    borderRadius: "8px",
                                    border: state.isFocused ? '1px solid rgb(88, 88, 88)' : '1px solid rgb(88, 88, 88)',
                                    boxShadow: state.isFocused ? null : null,
                                }),
                                indicatorContainer: (provided) => ({
                                    ...provided,
                                    padding: "2px 8px",
                                }),
                                hlgwow: (provided) => ({
                                    padding: "2px 8px",
                                    padding: "0px 8px",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    fontSize: '14px', // Change the font size of the menu
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    fontSize: "16px",
                                }),
                            }}
                        />
                    </div>
                    <div className="col-md-10 mb-3 mx-auto">
                        <label htmlFor="page_title" className='w-100 mb-2 '>Page Title</label>
                        <input
                            className="form-control"
                            type="text"
                            id="page_title"
                            placeholder="Enter page title.."
                            value={pageTitle} onChange={(e) => setPageTitle(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDomainModelClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleNextClick}>
                        Create Site
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WebSiteBuilder